import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { Button, Input, Modal, Pagination, Select, Table, Tag } from 'antd';
import CustomForm from '../../components/CustomForm';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { INITIAL_QUERY } from '../../helpers/pagination';
import { ColumnsType } from 'antd/lib/table';
import { GroupActionButton } from '../groupCompany';
import useCompany from '../../hooks/useCompany';
import { IOperationHeader } from '../../types/operationMode.type';
import ModalOperationMode from './components/modalOperationMode';
import { useNavigate } from 'react-router-dom';
import MasterOperatrionModeSvc from '../../services/OperationModeSvc';
import useFetchList from '../../hooks/useFetchList';

const OperationMode: React.FC = () => {
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { availableCompanies, setCurrentActiveCompany, activeCompany} = useCompany();
  const [selectedCompId, setSelectedCompId] = useState<string>(activeCompany?.compId ?? '');
  const { handlePermission, loginData } = useAuth();
  const [detail, setDetail] = useState<IOperationHeader>();
  const availableCompaniesMapped = useMemo(() => {
    if (availableCompanies && availableCompanies.length > 0) {
      const newCompanies = availableCompanies?.map((item) => ({
        value: item.compId,
        label: item.compName,
      }));

      return [{ value: 'all', label: 'All Company' }, ...newCompanies];
    }

    return [];
  }, [availableCompanies]);

  const {
    query,
    isLoading: loadingFetchListOperationMode,
    data,
    pagination,
    setSearch,
    changePage,
    fetchList,
    setQuery,
  } = useFetchList<IOperationHeader>({
    endpoint: '/master/Rpm_Operation_Header',
    initialQuery: {
      page: 1,
      max: INITIAL_QUERY.max,
      findField: 'mrohCompId',
      findValue: selectedCompId,
    },
  });

  function onChangeCompany(mrohCompId: any) {
    setSelectedCompId(mrohCompId);
    setCurrentActiveCompany(mrohCompId);
    setQuery((curr) => ({
      ...curr,
      findField: 'mrohCompId',
      findValue: mrohCompId,
    }));
  }

  const columns: ColumnsType<IOperationHeader> = [
    {
      title: 'Asset Name (ID)',
      dataIndex: 'mrohMassId',
      render: (value, record) => (
        <span>
          {record.mrohMass.massName} ({value})
        </span>
      ),
    },
    {
      title: 'Company (ID)',
      dataIndex: 'mrohCompId',
      render: (value, record) => (
        <span>
          {record.mrohComp.compName} ({value})
        </span>
      ),
    },
    {
      title: 'Device (ID)',
      dataIndex: 'mrohDevcId',
      render: (value, record) => (
        <span>
          {record.mrohDevc.devcName} ({value})
        </span>
      ),
    },
    {
      title: 'Start Time',
      dataIndex: 'mrohStartTime',
    },
    {
      title: 'Status',
      dataIndex: 'mrohStatus',
      render: (value: string) => (
        <Tag color={value === '1' ? '#55C95A' : '#FE4545'}>
          {value === '1' ? 'ON' : 'OFF'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <GroupActionButton>
            {handlePermission(['master_rpm_operation_header'], 'update') && (
              <Button
                onClick={() => handleMenuClick('edit', record)}
                size='small'
                value='edit'
              >
                Edit
              </Button>
            )}

            <Button
              onClick={() =>
                navigate(`/operation-mode/detail/${record.mrohId}`)
              }
              size='small'
              value='detail'
            >
              Detail
            </Button>

            {handlePermission(['master_rpm_operation_header'], 'delete') && (
              <Button
                onClick={() => handleMenuClick('delete', record)}
                size='small'
                danger
                value='delete'
              >
                Delete
              </Button>
            )}
          </GroupActionButton>
        );
      },
    },
  ];

  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.currentTarget.value;
  //   if (!value) {
  //     setQuery(() => ({
  //       page: 1,
  //       max: INITIAL_QUERY.max,
  //     }));
  //   } else {
  //     setSearch({
  //       findField: 'mrohMass',
  //       findValue: e.currentTarget.value,
  //     });
  //   }
  // };

  const handleMenuClick = (item: any, data: IOperationHeader) => {
    if (item === 'edit') {
      setDetail(data);
      setIsOpenModal(true);
    } else if (item === 'delete') {
      confirmDelete(data.mrohId);
    }
  };

  async function handleDeleteMasterHeader(id: string) {
    try {
      await MasterOperatrionModeSvc.deleteMasterHeader(id);
    } catch (error) {
      console.error('failed delete master header', error);
    }
  }

  const confirmDelete = (id: string) => {
    Modal.confirm({
      title: 'Delete Operation Mode?',
      content: `Are you sure want to delete this operation data?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDeleteMasterHeader(id);
      },
      afterClose: async () => {
        setQuery((currQuery) => ({
          ...currQuery,
          page: 1,
        }));
      },
    });
  };

  const handleAfterSubmit = async () => {
    setIsOpenModal(false);
    setDetail(undefined);
    setQuery((currQuery) => ({
      ...currQuery,
      page: 1,
    }));
  };

  return (
    <Container>
      <FilterContainer style={{ marginTop: 10 }}>
        {/* <CustomForm label='Search'>
          <Input
            placeholder='Search by name'
            prefix={<SearchOutlined />}
            allowClear
            onChange={handleSearch}
          />
        </CustomForm> */}
        <CustomForm label='Company'>
          <Select
            placeholder='Filter by company'
            showSearch
            optionFilterProp='children'
            value={selectedCompId}
            onChange={onChangeCompany}
            options={availableCompaniesMapped || []}
          />
        </CustomForm>
        <div />

        {handlePermission(['master_rpm_operation_header'], 'create') && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add Operation Mode
          </Button>
        )}
      
      </FilterContainer>

      <Table
        loading={loadingFetchListOperationMode}
        size='small'
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => {
          changePage(page, perPage);
        }}
      />

      <ModalOperationMode
        isOpen={isOpenModal}
        data={detail}
        afterSubmit={handleAfterSubmit}
        compId={selectedCompId ?? ''}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 20px;
  background-color: white;
`;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 250px 350px 1fr auto;
  padding: 0px 0px 15px;
`;

export default OperationMode;
