import moment from 'moment';
import 'moment-timezone';
import AssetSvc from '../services/AssetSvc';
import SummarySvc from '../services/SummarySvc';
import {
  AssetType,
  initialAssetType,
  OperationModeSeriesType,
  OperationModeSummaryType,
} from '../types/asset.type';
import { createGlobalState } from 'react-hooks-global-state';
import { dateUtil, roundTimestampsToNearestMinute } from '../helpers/dateUtil';
import { ISummary, ISummarySingleDevice } from '../types/summary.type';
import { emsAssets, singleEngineAssets } from '../helpers/constant';
import axios from 'axios';
import { useEffect, useMemo } from 'react';
import useFilter from './useFilter';
import DeviceSvc from '../services/DeviceSvc';
import {
  APP_DATA_SOURCE,
  APP_DATA_TIMEZONE,
  getSource,
  getTimezone,
} from '../helpers/asset';
import useCompany from './useCompany';
import MasterOperatrionModeSvc from '../services/OperationModeSvc';
import {
  initOperationTemplate,
  IOperationDetail,
  IOperationHeader,
} from '../types/operationMode.type';

// moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);

interface IParamsSummary {
  start?: string;
  end?: string;
  devcMassId?: string;
  devcType?: string[];
  aggregatedUnit?: string;
  aggregatedLength?: number;
}

interface AssetProps {
  isLoadingAsset: boolean;
  assets: { [T: string]: AssetType };
  assets2: AssetType[];
  asset: AssetType;
  isEMS: boolean;
  isSingleEngine: boolean;
  isBunker: boolean;
  assetObject: { [T: string]: AssetType };
  assetGPSInfo: {
    label: string;
    value: string;
    iconType: string;
    unit?: string;
  }[];
  combinedData: any;
  selectedAssetId: string;
  isLoadingAssetSummary: boolean;
  isLoadingPulse: boolean;
  loadingCurrAssetTrail?: string;
  currentDataSource: string;
  loadingAllAssetTrail: boolean;
  chartType: string;
  gpsDotTrail?: string | undefined;
  isLoadingDownload: boolean;
  currentDataTimezone: number;
}

const initialState: AssetProps = {
  isLoadingAsset: false,
  assets: {},
  assets2: [],
  asset: { ...initialAssetType },
  isEMS: false,
  isSingleEngine: false,
  isBunker: false,
  assetObject: {},
  assetGPSInfo: [],
  combinedData: {},
  selectedAssetId: '',
  isLoadingAssetSummary: false,
  isLoadingPulse: false,
  currentDataSource: 'gsm',
  loadingCurrAssetTrail: undefined,
  loadingAllAssetTrail: false,
  chartType: 'column',
  gpsDotTrail: 'active',
  isLoadingDownload: false,
  currentDataTimezone: (new Date().getTimezoneOffset() / 60) * -1,
};

const { useGlobalState } = createGlobalState(initialState);

export default function useAsset() {
  const [isLoadingAsset, setIsLoadingAsset] = useGlobalState('isLoadingAsset');
  const [isLoadingAssetSummary, setIsLoadingAssetSummary] = useGlobalState(
    'isLoadingAssetSummary'
  );
  const [isLoadingPulse, setIsLoadingPulse] = useGlobalState('isLoadingPulse');
  const [isLoadingDownload, setLoadingDownload] =
    useGlobalState('isLoadingDownload');
  const [loadingCurrAssetTrail, setLoadingCurrAssetTrail] = useGlobalState(
    'loadingCurrAssetTrail'
  );
  const [loadingAllAssetTrail, setLoadingAllAssetTrail] = useGlobalState(
    'loadingAllAssetTrail'
  );
  const [assets, setAssets] = useGlobalState('assets');
  const [assets2, setAssets2] = useGlobalState('assets2');
  const [asset, setAsset] = useGlobalState('asset');
  const [isEMS, setIsEMS] = useGlobalState('isEMS');
  const [isSingleEngine, setIsSingleEngine] = useGlobalState('isSingleEngine');
  const [isBunker, setIsBunker] = useGlobalState('isBunker');
  const [assetObject, setAssetObject] = useGlobalState('assetObject');
  const [combinedData, setCombinedData] = useGlobalState('combinedData');
  const [assetGPSInfo, setAssetGPSInfo] = useGlobalState('assetGPSInfo');
  const [selectedAssetId, setSelectedAssetId] =
    useGlobalState('selectedAssetId');
  const [currentDataSource, setCurrentDataSource] =
    useGlobalState('currentDataSource');
  const [chartType, setChartType] = useGlobalState('chartType');
  const [currentDataTimezone, setCurrentDataTimezone] = useGlobalState(
    'currentDataTimezone'
  );

  const { activeCompany } = useCompany();

  async function fetchAssets(result?: Boolean) {
    !result && setIsLoadingAsset(true);
    try {
      const resAssets = await AssetSvc.getAssets();
      const resDevice = await DeviceSvc.getDevices({
        params: {
          max: 300,
          page: 1,
        },
      });
      let resHeader;

      /**
       * isCompHaveOpsMode & isCompHaveThreeEngine
       * digunakan untuk pengkondisian pengambilan dan tampilan
       * opsMode/Three engine
       */

      /** jika memiliki ops mode maka get data header */
      // if (isCompHaveOpsMode) {
      //   resHeader = await MasterOperatrionModeSvc.getAllMasterHeader({
      //     findField: "mrohCompId",
      //     findValue: activeCompany?.compId,
      //   });
      // }

      if (resAssets.data && resAssets.data.data) {
        let newAssets: AssetType[] = [];

        for (let asset of resAssets.data.data) {
          const isCompHaveOpsMode =
            asset?.massCompId === '9' ||
            asset?.massCompId === '41' ||
            asset?.massCompId === '4' ||
            activeCompany?.compGrcpId === '19';
          const isCompHaveThreeEngine =
            asset?.massCompId === '44' || asset?.massCompId === '4';

          let rpmDevice: any = {};
          /* 
            set data device secara manual
            karena tidak ada balikan massDevice 
            untuk role selain superadmin
           */
          const thisAssetDevices = resDevice.data.data
            .map((elm: any) => {
              if (elm.devcMassId === asset.massId) {
                return elm;
              }
            })
            .filter((elm: any) => elm !== undefined);

          /**
           * mengambil data ops mode detail untuk mapping template
           * yang di gunakan pada table table ops mode summary
           */
          if (isCompHaveOpsMode) {
            rpmDevice = thisAssetDevices.find(
              (elm: any) => elm.devcType === 'rpm'
            );
            if (rpmDevice?.devcId) {
              // const assetHeader =
              //   resHeader &&
              //   resHeader.data.data.find(
              //     (elm) => elm.mrohDevcId === rpmDevice.devcId
              //   );
              //
              // const opsModeDetail =
              //   await MasterOperatrionModeSvc.getAllMasterDetail({
              //     findField: "mrodMrohId",
              //     findValue: assetHeader?.mrohId ?? "",
              //   });

              newAssets.push({
                ...asset,
                massDevice: resDevice.data.data
                  .map((elm: any) => {
                    if (elm.devcMassId === asset.massId) {
                      return elm;
                    }
                  })
                  .filter((elm: any) => elm !== undefined),
                // opsModeTemplate: opsModeDetail.data.data,
                opsModeTemplate: initOperationTemplate,
                isEMS: asset.massSystemType === 'EMS',
                isHaveOperationMode: isCompHaveOpsMode,
                isThreeEngine: isCompHaveThreeEngine,
              });
            }
          } else {
            newAssets.push({
              ...asset,
              massDevice: resDevice.data.data
                .map((elm: any) => {
                  if (elm.devcMassId === asset.massId) {
                    return elm;
                  }
                })
                .filter((elm: any) => elm !== undefined),
              isEMS: asset.massSystemType === 'EMS',
              isHaveOperationMode: isCompHaveOpsMode,
              isThreeEngine: isCompHaveThreeEngine,
            });
          }
        }

        /**
         * karena fungsi digunakan untuk fetchDevicePulse & fetchAssetsWithLastData
         * maka digunakan hanya akan mereturn hasil dari asset
         * tanpa mengatur assets2 (assets2nya di atur di 2 fungsi tsb.)
         */
        if (result) {
          return newAssets;
        }
        setAssets2(newAssets);
      }
    } catch (error: any) {
      console.error('failed get assets: ', error);
    } finally {
      setIsLoadingAsset(false);
    }
  }

  function generateSeriesParam(params?: IParamsSummary) {
    let start = moment().startOf('day').valueOf();
    let end = moment().valueOf();

    if (params?.start) {
      start = Number(params?.start);
    }

    if (params?.end) {
      end = Number(params?.end);
    }

    const aggregatedUnit = params?.aggregatedUnit || 'MINUTE';

    const seriesParam = dateUtil.autoMaxData(
      dateUtil.msToS({
        range: {
          start,
          end,
        },
        aggregatedUnit,
      })
    );

    return { start, end, seriesParam };
  }

  async function fetchSummary(
    params?: IParamsSummary
  ): Promise<ISummary | undefined> {
    try {
      const { start, end, seriesParam } = generateSeriesParam(params);
      const localDataTimezone = Number(getTimezone()) || 7;
      const timezoneCalc = localDataTimezone > 7 ? localDataTimezone - 7 : 0;
      const constanta = 60 * 60 * 1000 * timezoneCalc;
      let newStart = start;
      let newEnd = end;

      if (!params?.start) {
        newStart = start;
        newEnd = end;
      } else {
        newStart = start;
        newEnd = end;
      }

      const response = await SummarySvc.getSummary({
        start: Math.round(newStart / 1000).toString(),
        end: Math.round(newEnd / 1000).toString(),
        params: {
          devcMassId: params?.devcMassId,
          devcType: params?.devcType || ['gps', 'rpm', 'flowmeter', 'ae'],
          aggregatedUnit: params?.aggregatedUnit || seriesParam.aggregatedUnit,
          aggregatedLength:
            params?.aggregatedLength || seriesParam.aggregatedLength,
          timezone: seriesParam.timezone,
        },
        dataSource: localDataSource || 'gsm',
      });

      if (
        !response.data.data ||
        (typeof response.data.data === 'object' &&
          Object.keys(response.data.data as any).length === 0)
      ) {
        return undefined;
      }
      return {
        gps: response.data.data.gps
          ? {
              summary: response.data.data.gps.summary,
              data: Object.values(response.data.data.gps.data),
              timestamps: Object.keys(response.data.data.gps.data).map(
                (item) =>
                  roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
              ),
            }
          : undefined,
        rpm: response.data.data.rpm
          ? {
              summary: response.data.data.rpm.summary,
              data: Object.values(response.data.data.rpm.data),
              timestamps: Object.keys(response.data.data.rpm.data).map(
                (item) =>
                  roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
              ),
            }
          : undefined,
        flowmeter: response.data.data.flowmeter
          ? {
              summary: response.data.data.flowmeter.summary,
              data: Object.values(response.data.data.flowmeter.data),
              timestamps: Object.keys(response.data.data.flowmeter.data).map(
                (item) =>
                  roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
              ),
            }
          : undefined,
        ae:
          response.data.data.ae && response.data.data.ae.summary
            ? {
                summary: response.data.data.ae.summary,
                data: Object.values(response.data.data.ae.data),
                timestamps: Object.keys(response.data.data.ae.data).map(
                  (item) =>
                    roundTimestampsToNearestMinute(Number(item) * 1000) / 1000
                ),
              }
            : undefined,
      };
    } catch (error) {
      console.error('failed fetch summary', error);
    }
  }

  async function fetchSummarySingleDevice(params?: IParamsSummary) {
    try {
      const { start, end, seriesParam } = generateSeriesParam(params);

      const response = await SummarySvc.getSummary({
        start: Math.floor(start / 1000).toString(),
        end: Math.floor(end / 1000).toString(),
        params: {
          devcMassId: params?.devcMassId,
          devcType: params?.devcType || ['gps', 'rpm', 'flowmeter', 'ae'],
          aggregatedUnit: params?.aggregatedUnit || seriesParam.aggregatedUnit,
          aggregatedLength:
            params?.aggregatedLength || seriesParam.aggregatedLength,
          timezone: seriesParam.timezone,
        },
      });

      if (
        !response.data.data ||
        (typeof response.data.data === 'object' &&
          (response.data.data as any).length === 0)
      ) {
        return undefined;
      }

      if (
        response.data.data.gps &&
        Object.keys(response.data.data.gps.data).length > 0
      ) {
        return {
          summary: response.data.data.gps.summary,
          data: Object.values(response.data.data.gps.data),
          timestamps: Object.keys(response.data.data.gps.data),
        };
      } else if (
        response.data.data.rpm?.summary &&
        Object.keys(response.data.data.rpm.summary).length > 0
      ) {
        return {
          summary: response.data.data.rpm.summary,
          data: Object.values(response.data.data.rpm.data),
          timestamps: Object.keys(response.data.data.rpm.data),
        };
      }

      if (response.data.data.flowmeter?.data) {
        return {
          summary: response.data.data.flowmeter.summary,
          data: Object.values(response.data.data.flowmeter.data),
          timestamps: Object.keys(response.data.data.flowmeter.data),
        };
      }

      if (response.data.data.ae?.summary) {
        return {
          summary: response.data.data.ae.summary,
          data: Object.values(response.data.data.ae.data),
          timestamps: Object.keys(response.data.data.ae.data),
        };
      }
    } catch (error) {
      console.error('failed fetch single summary', error);
    }
  }

  async function fetchDevicePulse(customParams?: IParamsSummary) {
    setIsLoadingPulse(true);
    try {
      const oldResAssets: AssetType[] | undefined =
        assets2.length > 0 ? assets2 : await fetchAssets(true);
      const newResAssets: AssetType[] = [];

      let resAssetsMapped: AssetType[] = oldResAssets ? oldResAssets : [];
      let currMassId = customParams?.devcMassId;

      if (
        currMassId &&
        currMassId === 'initial' &&
        oldResAssets &&
        oldResAssets.length > 0
      ) {
        resAssetsMapped = [oldResAssets[0]];
      } else if (currMassId) {
        resAssetsMapped = oldResAssets
          ? oldResAssets.filter((item) => item.massId === currMassId)
          : [];
      }

      if (resAssetsMapped.length > 0) {
        /**
         * This function handle assets that doesn't have massDevice data
         */
        const assetMappedByManual: any[] = [];
        const assetDoesntHaveMassDevice = resAssetsMapped.filter(
          (item) => !item.massDevice
        );

        if (assetDoesntHaveMassDevice.length > 0) {
          console.log(
            'device that does not have massDevice',
            assetDoesntHaveMassDevice
          );
          for (let i = 0; i < assetDoesntHaveMassDevice.length; i++) {
            const item = assetDoesntHaveMassDevice[i];
            const deviceResults = await DeviceSvc.getDevices({
              params: {
                findField: 'devcMassId',
                findValue: item.massId,
              },
            });

            assetMappedByManual.push({
              ...item,
              massDevice: deviceResults.data.data,
            });
          }

          resAssetsMapped = resAssetsMapped.map((item) => {
            const found = assetMappedByManual.find(
              (a) => a.massId === item.massId
            );

            return found
              ? { ...item, massDevice: found.massDevice }
              : { ...item };
          });
        }

        const GpsdeviceIds = resAssetsMapped
          .map(
            (item) =>
              item.massDevice.find((item: any) => item.devcType === 'gps')
                ?.devcUniqueId
          )
          .filter((item) => item);
        const RpmdeviceIds = resAssetsMapped
          .map(
            (item) =>
              item.massDevice.find((item: any) => item.devcType === 'rpm')
                ?.devcUniqueId
          )
          .filter((item) => item);

        const lastDataAssetsGps = await SummarySvc.getLastData(
          'gps',
          GpsdeviceIds,
          localDataSource || 'gsm'
        );
        const lastDataAssetsRpm = await SummarySvc.getLastData(
          'rpm',
          RpmdeviceIds,
          localDataSource || 'gsm'
        );

        for (let i = 0; i < resAssetsMapped.length; i++) {
          const data = resAssetsMapped[i];
          const dataDeviceGps = resAssetsMapped[i].massDevice.find(
            (item: any) => item.devcType === 'gps'
          );
          const dataDeviceRpm = resAssetsMapped[i].massDevice.find(
            (item: any) => item.devcType === 'rpm'
          );

          if (dataDeviceGps || dataDeviceRpm) {
            const currentLastDataGps = lastDataAssetsGps.find(
              (item: { deviceId: any }) =>
                item.deviceId === dataDeviceGps.devcUniqueId
            );
            const currentLastDataRpm = lastDataAssetsRpm.find(
              (item: { deviceId: any }) =>
                item.deviceId === dataDeviceRpm.devcUniqueId
            );
            let dataStatus: 'Online' | 'Offline' | 'Waiting Data' = 'Offline';
            let isEngineOn = false;

            if (currentLastDataGps) {
              const currentTime = moment().unix();
              const lastDataTime = currentLastDataGps.timestamp;

              if (localDataSource === 'satellite') {
                if (currentTime - lastDataTime <= 86400) {
                  // 86400 s = 24 jam
                  if (currentTime - lastDataTime > 7200) {
                    // 7200 s = 2 jam
                    dataStatus = 'Waiting Data';
                  } else {
                    dataStatus = 'Online';
                  }
                } else {
                  dataStatus = 'Offline';
                }
              } else {
                if (currentTime - lastDataTime <= 86400) {
                  // 86400 s = 24 jam
                  if (currentTime - lastDataTime > 600) {
                    // 600 s = 10 menit
                    dataStatus = 'Waiting Data';
                  } else {
                    dataStatus = 'Online';
                  }
                } else {
                  dataStatus = 'Offline';
                }
              }
            }

            if (currentLastDataRpm) {
              const currentTime = moment().unix();
              const lastDataTime = currentLastDataRpm.timestamp;
              const isNotMoreThanTwoHours = currentTime - lastDataTime <= 7200;
              const isNotMoreThanTenMinutes = currentTime - lastDataTime <= 600;

              if (localDataSource === 'satellite') {
                isEngineOn =
                  isNotMoreThanTwoHours && currentLastDataRpm.rpm > 0;
              } else {
                isEngineOn =
                  isNotMoreThanTenMinutes && currentLastDataRpm.rpm > 0;
              }
            }

            newResAssets.push({
              ...data,
              dataSummary: undefined,
              dataStatus: dataStatus,
              checked: true,
              lastDataGps: currentLastDataGps,
              firstDataGps: currentLastDataGps,
              showTrail: false,
              isEngineOn,
            });
          }
        }
        console.debug('newResAssets ==> ', newResAssets);
        setAssets2(newResAssets);
      }
    } catch (error) {
      console.error(`failed get last data pulse`, error);
    } finally {
      setIsLoadingPulse(false);
    }
  }

  async function fetchCurrentAssetTrail(params: {
    massId: string;
    devcMassId: string;
    devcType: string;
    start?: any;
    end?: any;
  }) {
    setLoadingCurrAssetTrail(params.massId);
    try {
      const resDataSummary: ISummary | undefined = await fetchSummary({
        aggregatedUnit: 'MINUTE',
        aggregatedLength: 1,
        start: params.start,
        end: params.end,
        devcMassId: params.devcMassId,
        devcType: [params.devcType],
      });

      if (resDataSummary?.gps?.data) {
        if (resDataSummary?.gps?.data.length > 0) {
          const newAssets2 = assets2.map((item) => {
            if (item.massId === params.massId) {
              return {
                ...item,
                dataSummary: resDataSummary,
                firstDataGps: resDataSummary?.gps?.data[0],
              };
            } else {
              return item;
            }
          });

          setAssets2(newAssets2);

          toggleAssetTrail({
            massId: params.massId,
          });
        }
      }
    } catch (error) {
      console.error('failed fetch asset trail', error);
    } finally {
      setLoadingCurrAssetTrail(undefined);
    }
  }

  async function fetchAssetsWithLastData(
    customParams?: IParamsSummary,
    additionalParams?: {
      showAllAssetTrail?: boolean;
    }
  ) {
    try {
      const newResAssets: AssetType[] = [];
      let resAssetsMapped: AssetType[] = [];
      let currMassId: string | undefined = undefined;

      if (
        !additionalParams ||
        typeof additionalParams.showAllAssetTrail !== 'boolean'
      ) {
        setIsLoadingAssetSummary(true);
        const resAssets = await fetchAssets(true);
        let resAssetsMapped: AssetType[] = [];

        const currMassId = customParams?.devcMassId;

        if (resAssets && currMassId) {
          if (currMassId === 'initial') {
            resAssetsMapped = [resAssets[0]];
          } else {
            resAssetsMapped = resAssets.filter(
              (item) => item.massId === currMassId
            );
          }
        }

        if (resAssetsMapped.length > 0) {
          for (let i = 0; i < resAssetsMapped.length; i++) {
            const data = resAssetsMapped[i];
            const resDataSummary: any | undefined = await fetchSummary({
              ...customParams,
              devcMassId:
                customParams?.devcMassId &&
                customParams?.devcMassId !== 'initial'
                  ? customParams?.devcMassId
                  : data.massId,
            });
            let resDataOpsMode;

            if (data.isHaveOperationMode) {
              const rpmDevice = data.massDevice.find(
                (elm: any) => elm.devcType === 'rpm'
              );

              const { start, end } = generateSeriesParam(customParams);

              const currStartDate = Math.round(start / 1000).toString();
              const currEndDate = Math.round(end / 1000).toString();

              resDataOpsMode = await fetchOperationMode(
                currStartDate,
                currEndDate,
                rpmDevice?.devcId
              );
            }

            const currentTime = moment().valueOf();
            const currentDay = moment().startOf('day').valueOf();
            const lastGpsDataTime =
              resDataSummary.gps && resDataSummary.gps.data.length > 0
                ? resDataSummary.gps.data[resDataSummary.gps.data.length - 1]
                    .timestamp * 1000
                : 0;
            const lastGpsDataDay = lastGpsDataTime
              ? moment(lastGpsDataTime).startOf('day').valueOf()
              : 0;

            const dataStatus = !resDataSummary
              ? 'Offline'
              : resDataSummary.gps && resDataSummary.gps.data.length === 0
              ? 'Offline'
              : currentDay === lastGpsDataDay
              ? currentTime - lastGpsDataTime > 600000
                ? 'Waiting Data'
                : 'Online'
              : 'Offline';

            newResAssets.push({
              ...data,
              dataSummary: !resDataSummary ? undefined : { ...resDataSummary },
              dataStatus,
              checked: !resDataSummary ? false : true,
              lastDataGps:
                !resDataSummary || !resDataSummary.gps?.data
                  ? undefined
                  : resDataSummary.gps.data[resDataSummary.gps.data.length - 1],
              firstDataGps:
                !resDataSummary || !resDataSummary.gps?.data
                  ? undefined
                  : resDataSummary.gps.data[0],
              showTrail: false,
              isSingleEngine:
                singleEngineAssets.includes(data.massId) ||
                resDataSummary?.rpm?.summary.singleEngine ||
                false,
              operationModeSeries: resDataOpsMode?.series,
              operationModeSummary: resDataOpsMode?.summary,
            });
          }

          if (currMassId && resAssets) {
            setAssets2(
              resAssets.map((item) => {
                if (item.massId === newResAssets[0].massId) {
                  return {
                    ...newResAssets[0],
                  };
                }

                return {
                  ...item,
                };
              })
            );
          } else {
            setAssets2(
              newResAssets.map((item) => ({
                ...item,
                showTrail: additionalParams?.showAllAssetTrail || false,
              }))
            );
          }
        }
      } else if (assets2.length > 0) {
        const newResAssets2: AssetType[] = [];

        if (assets2.length > 0 && additionalParams.showAllAssetTrail) {
          for (let i = 0; i < assets2.length; i++) {
            const element = assets2[i];

            const resDataSummary: ISummary | undefined = await fetchSummary({
              ...customParams,
              devcMassId: element?.massId,
            });

            newResAssets2.push({
              ...element,
              dataSummary: !resDataSummary ? undefined : { ...resDataSummary },
              showTrail: additionalParams.showAllAssetTrail,
            });
          }

          setAssets2(newResAssets2);
        } else {
          setAssets2((current) =>
            current.map((item) => ({
              ...item,
              showTrail: false,
            }))
          );
        }
      }
    } catch (error: any) {
      console.error('failed get assets & summary: ', error);
    } finally {
      setIsLoadingAssetSummary(false);
    }
  }

  async function toggleAssetTrail(
    params: { all?: boolean; massId?: string },
    customParams?: IParamsSummary
  ) {
    try {
      setLoadingAllAssetTrail(true);
      if (typeof params.all === 'boolean') {
        if (!params.all) {
          setAssets2((oldVal) =>
            oldVal.map((item) => ({ ...item, showTrail: false }))
          );
        } else {
          await fetchAssetsWithLastData(
            {
              ...customParams,
            },
            {
              showAllAssetTrail: params.all,
            }
          );
        }
      }

      if (params.massId) {
        setAssets2((oldValue) =>
          oldValue.map((item) =>
            item.massId === params.massId
              ? { ...item, showTrail: !item.showTrail }
              : { ...item }
          )
        );
      }
    } catch (error) {
      console.error('failed toggle all asset trail', error);
    } finally {
      setLoadingAllAssetTrail(false);
    }
  }

  const { dataFilter } = useFilter();

  const currentAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter.massId, assets2]);

  const dataCompletion = useMemo(() => {
    if (currentAsset && currentAsset.dataSummary) {
      const timestampGPS = currentAsset.dataSummary.gps?.timestamps;
      const timestampRPM = currentAsset.dataSummary.rpm?.timestamps;
      const timestampFM = currentAsset.dataSummary.flowmeter?.timestamps;
      const timestampAE = currentAsset.dataSummary.ae?.timestamps;
      return {
        gps: {
          count: timestampGPS?.length,
          lastTimestamp:
            timestampGPS && timestampGPS.length > 0
              ? timestampGPS[timestampGPS.length - 1] * 1000
              : 0,
        },
        rpm: {
          count: timestampRPM?.length,
          lastTimestamp:
            timestampRPM && timestampRPM.length > 0
              ? timestampRPM[timestampRPM.length - 1] * 1000
              : 0,
        },
        flowmeter: {
          count: timestampFM?.length || 0,
          lastTimestamp: timestampFM
            ? timestampFM[timestampFM.length - 1] * 1000
            : 0,
        },
        ae: {
          count: timestampAE?.length,
          lastTimestamp:
            timestampAE && timestampAE.length > 0
              ? timestampAE[timestampAE.length - 1] * 1000
              : 0,
        },
      };
    }
  }, [currentAsset]);

  const localDataSource = getSource();
  const localDataTimezone = getTimezone();

  useEffect(() => {
    if (localDataSource) {
      setCurrentDataSource(localDataSource);
    }
  }, [localDataSource]);

  function setCurrentSource(value: 'gsm' | 'satellite') {
    localStorage.setItem(APP_DATA_SOURCE, value);
    setCurrentDataSource(value);
  }

  useEffect(() => {
    // localStorage.setItem(
    //   APP_DATA_TIMEZONE,
    //   ((new Date().getTimezoneOffset() / 60) * -1).toString()
    // );
    if (localDataTimezone) {
      setCurrentDataTimezone(Number(localDataTimezone));
    } else {
      localStorage.setItem(
        APP_DATA_TIMEZONE,
        ((new Date().getTimezoneOffset() / 60) * -1).toString()
      );
    }
  }, [localDataTimezone]);

  function setCurrentTimezone(value: number) {
    localStorage.setItem(APP_DATA_TIMEZONE, value.toString());
    setCurrentDataTimezone(Number(value));
  }

  const [gpsDotTrail, setGPSDotTrail] = useGlobalState('gpsDotTrail');

  const handleOnDownloadPDF = async (currAsset: any, values: string[]) => {
    setLoadingDownload(true);
    try {
      const data = {
        start: Number(dataFilter.range.startAt) / 1000,
        end: Number(dataFilter.range.endAt) / 1000,
      };
      let reports: string[] = values;
      let reportName = 'reports';

      if (values.length === 1) {
        if (values.includes('summary')) {
          reportName = 'report-summary';
        } else if (values.includes('report-vessel-tracking')) {
          reportName = 'report-vessel-tracking';
        } else if (values.includes('rpm_speed_ae')) {
          reportName = 'report-rpm-vs-fuel-cons-vs-speed';
        } else if (values.includes('me_fuel_consumption_running')) {
          reportName = 'report-me-fuel-used-vs-running-hour';
        } else if (values.includes('port_fuel_consumption_running')) {
          reportName = 'report-port-fuel-used-vs-running-hour';
        } else if (values.includes('starboard_fuel_consumption_running')) {
          reportName = 'report-starboard-fuel-used-vs-running-hour';
        } else if (values.includes('me-fuel-consumption')) {
          reportName = 'report-me-fuel-consumption';
        } else if (values.includes('ae_fuel_consumption')) {
          reportName = 'report-ae-fuel-consumption';
        }
      }

      const response = await SummarySvc.downloadReportPDF(data, {
        devcMassId: dataFilter.massId,
        aggregatedUnit:
          dataFilter.interval === 'MINUTE'
            ? 'HOUR'
            : dataFilter.interval ?? 'HOUR',
        aggregatedLength: 1,
        reports,
        dataTable: true,
      });

      const filename = `${currAsset?.massName
        .toLowerCase()
        .replace(/ |-/g, '-')}__${reportName}__${moment(
        Number(dataFilter.range.startAt)
      ).format('YYYY-MM-DD_HH_mm')}__${moment(
        Number(dataFilter.range.endAt)
      ).format('YYYY-MM-DD_HH_mm')}.pdf`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('failed download report', error);
    } finally {
      setLoadingDownload(false);
    }
  };

  useEffect(() => {
    let dotTrail = localStorage.getItem('GPS_DOT_TRAIL') || 'active';
    setGPSDotTrail(dotTrail);
    localStorage.setItem('GPS_DOT_TRAIL', dotTrail);
  }, [localStorage.getItem('GPS_DOT_TRAIL')]);

  const handleGPSDotTrail: any = () => {
    if (gpsDotTrail === 'active') {
      setGPSDotTrail('inactive');
      localStorage.setItem('GPS_DOT_TRAIL', 'inactive');
    } else {
      setGPSDotTrail('active');
      localStorage.setItem('GPS_DOT_TRAIL', 'active');
    }
  };

  async function fetchOperationMode(
    start: any,
    end: any,
    devcId: string
  ): Promise<{
    series?: OperationModeSeriesType | undefined;
    summary?: OperationModeSummaryType | undefined;
  }> {
    if (!devcId) return { series: undefined, summary: undefined };

    const responseSeries = await SummarySvc.getSeriesOperationMode({
      // start: String(moment(dataFilter.range.startAt).valueOf() / 1000),
      // end: String(moment(dataFilter.range.endAt).valueOf() / 1000),
      start,
      end,
      params: { devcId: devcId },
    });

    const responseSummary = await SummarySvc.getSummaryOperationMode({
      // start: String(moment(dataFilter.range.startAt).valueOf() / 1000),
      // end: String(moment(dataFilter.range.endAt).valueOf() / 1000),
      start,
      end,
      params: { devcId: devcId },
    });

    return {
      series: responseSeries.data.data,
      summary: responseSummary.data.data,
    };
  }

  return {
    asset,
    assets,
    selectedAssetId,
    isLoadingAsset,
    isEMS,
    isBunker,
    assetObject,
    combinedData,
    assetGPSInfo,
    fetchAssets,
    setCombinedData,
    setAssetObject,
    setIsLoadingAsset,
    setAssets,
    setAsset,
    setIsEMS,
    setIsBunker,
    setAssetGPSInfo,
    setIsSingleEngine,
    isSingleEngine,
    setSelectedAssetId,
    fetchSummary,
    fetchSummarySingleDevice,
    fetchAssetsWithLastData,
    assets2,
    setAssets2,
    toggleAssetTrail,
    isLoadingAssetSummary,
    setCurrentDataSource,
    currentDataSource,
    generateSeriesParam,
    currentAsset,
    dataCompletion,
    fetchDevicePulse,
    fetchCurrentAssetTrail,
    loadingCurrAssetTrail,
    loadingAllAssetTrail,
    setChartType,
    chartType,
    setCurrentSource,
    gpsDotTrail,
    setGPSDotTrail,
    handleGPSDotTrail,
    isLoadingDownload,
    handleOnDownloadPDF,
    setCurrentTimezone,
    currentDataTimezone,
  };
}
