export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function showReadableText(str: string) {
  return str.replace(/_/g, ' ');
}

export function getLabelFromKeyOperationMode(key: string) {
  key = key.toLowerCase();
  if (key.includes('off')) return 'Off';
  if (key.includes('idle')) return 'Idle';
  if (key.includes('eco')) return 'Eco';
  if (key.includes('slow')) return 'Slow';
  if (key.includes('full')) return 'Full';
  return null;
}
