import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import AuthLayout from '../layout/AuthLayout';
import AuthHeaderCard from '../../components/AuthHeaderCard';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';

interface Props {
  step?: number;
}

const CompanySelection: React.FC<Props> = ({ step = 1 }) => {
  const [currentStep, setCurrentStep] = useState(step);
  const [stepTwoColor, setStepTwoColor] = useState('#FFFFFF');
  const [stepTwoFontColor, setStepTwoFontColor] = useState('#000000');
  const [selectedCard, setSelectedCard] = useState(-1);
  const [selectedCompany, setSelectedCompany] = useState([-1, -1]);

  const dummyConpanies = [
    {
      groupName: 'company1',
      companies: [
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
      ],
    },
    {
      groupName: 'company2',
      companies: [
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
      ],
    },
    {
      groupName: 'company3',
      companies: [
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
      ],
    },
    {
      groupName: 'company4',
      companies: [
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
      ],
    },
    {
      groupName: 'company5',
      companies: [
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
        {
          url: 'https://picsum.photos/200',
          name: 'juni',
          city: 'juni city',
        },
      ],
    },
  ];

  return (
    <AuthLayout variant='secondary'>
      <AuthHeaderCard
        title='Select Application & Company'
        subtitle='Select Group and Company that you want to trace'
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px',
          gap: '24px',
          width: '600px',
          height: '44px',
        }}
      >
        <Typography.Paragraph
          style={{
            float: 'left',
            fontWeight: 600,
            boxSizing: 'border-box',
            border: '2px solid #E8ECF3',
            borderRadius: '100px',
            height: '32px',
            width: '32px',
            textAlign: 'center',
            background: '#0957C2',
            color: '#FFFFFF',
          }}
        >
          {currentStep === 1 ? 1 : <CheckOutlined />}
        </Typography.Paragraph>
        <div style={{ float: 'left' }}>
          <Typography.Paragraph
            style={{
              color: '#0957C2',
              fontFamily: 'Inter',
              marginBottom: '0px',
            }}
          >
            Application
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontFamily: 'Inter' }}>
            Select your application
          </Typography.Paragraph>
        </div>
        <div
          style={{
            height: '1px',
            width: '150px',
            border: '1px solid #C5CDDB',
            marginTop: '-10px',
            float: 'left',
          }}
        ></div>
        <Typography.Paragraph
          style={{
            float: 'left',
            fontWeight: 600,
            boxSizing: 'border-box',
            border: '2px solid #E8ECF3',
            borderRadius: '100px',
            height: '32px',
            width: '32px',
            textAlign: 'center',
            background: stepTwoColor,
            color: stepTwoFontColor,
          }}
        >
          2
        </Typography.Paragraph>
        <div style={{ float: 'left' }}>
          <Typography.Paragraph
            style={{
              color: '#0957C2',
              fontFamily: 'Inter',
              marginBottom: '0px',
            }}
          >
            Company
          </Typography.Paragraph>
          <Typography.Paragraph style={{ fontFamily: 'Inter' }}>
            Select your company
          </Typography.Paragraph>
        </div>
      </div>
      {currentStep === 1 ? (
        <React.Fragment>
          <div style={{ marginTop: '-20px' }}>
            <Row>
              <Col style={{ margin: '10px' }}>
                <Card
                  style={
                    selectedCard === 1
                      ? {
                          height: '190px',
                          width: '312px',
                          textAlign: 'center',
                          border: '3px solid #0957C2',
                        }
                      : { height: '190px', width: '312px', textAlign: 'center' }
                  }
                  onClick={() => setSelectedCard(1)}
                >
                  <Space direction='vertical'>
                    <img
                      alt='icon'
                      src='/images/fuel.png'
                      style={{ height: '80px' }}
                    />
                    <Typography.Paragraph
                      style={
                        selectedCard === 1
                          ? { fontWeight: 'bold', color: '#0957C2' }
                          : { fontWeight: 'bold' }
                      }
                    >
                      Fuel Monitoring System
                    </Typography.Paragraph>
                  </Space>
                </Card>
              </Col>
              <Col style={{ margin: '10px' }}>
                <Card
                  style={
                    selectedCard === 2
                      ? {
                          height: '190px',
                          width: '312px',
                          textAlign: 'center',
                          border: '3px solid #0957C2',
                        }
                      : { height: '190px', width: '312px', textAlign: 'center' }
                  }
                  onClick={() => setSelectedCard(2)}
                >
                  <Space direction='vertical'>
                    <img
                      alt='icon'
                      src='/images/asset.png'
                      style={{ height: '80px' }}
                    />
                    <Typography.Paragraph
                      style={
                        selectedCard === 2
                          ? { fontWeight: 'bold', color: '#0957C2' }
                          : { fontWeight: 'bold' }
                      }
                    >
                      Asset Monitoring
                    </Typography.Paragraph>
                  </Space>
                </Card>
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: '-20px' }}>
            <Row>
              <Col style={{ margin: '10px' }}>
                <Card
                  style={
                    selectedCard === 3
                      ? {
                          height: '190px',
                          width: '312px',
                          textAlign: 'center',
                          border: '3px solid #0957C2',
                        }
                      : { height: '190px', width: '312px', textAlign: 'center' }
                  }
                  onClick={() => setSelectedCard(3)}
                >
                  <Space direction='vertical'>
                    <img
                      alt='icon'
                      src='/images/bunkering.png'
                      style={{ height: '80px' }}
                    />
                    <Typography.Paragraph
                      style={
                        selectedCard === 3
                          ? { fontWeight: 'bold', color: '#0957C2' }
                          : { fontWeight: 'bold' }
                      }
                    >
                      Bunkering Monitoring
                    </Typography.Paragraph>
                  </Space>
                </Card>
              </Col>
              <Col style={{ margin: '10px' }}>
                <Card
                  style={
                    selectedCard === 4
                      ? {
                          height: '190px',
                          width: '312px',
                          textAlign: 'center',
                          border: '3px solid #0957C2',
                        }
                      : { height: '190px', width: '312px', textAlign: 'center' }
                  }
                  onClick={() => setSelectedCard(4)}
                >
                  <Space direction='vertical'>
                    <img
                      alt='icon'
                      src='/images/tank-level.png'
                      style={{ height: '80px' }}
                    />
                    <Typography.Paragraph
                      style={
                        selectedCard === 4
                          ? { fontWeight: 'bold', color: '#0957C2' }
                          : { fontWeight: 'bold' }
                      }
                    >
                      Tank Level Monitoring
                    </Typography.Paragraph>
                  </Space>
                </Card>
              </Col>
            </Row>
          </div>
          <Divider />
          <Button
            style={{
              marginTop: '-25px',
              width: '650px',
              height: '44px',
              background: '#0957C2',
              borderRadius: '6px',
              color: 'white',
            }}
            onClick={() => {
              setCurrentStep(2);
              setStepTwoColor('#0957C2');
              setStepTwoFontColor('#FFFFFF');
            }}
          >
            Next
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Input
            placeholder='Search by company or group name'
            prefix={<SearchOutlined />}
            style={{ width: '90%', height: '44px' }}
          />
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              width: '100%',
              paddingBottom: '10%',
            }}
          >
            <Collapse accordion style={{ alignSelf: 'stretch' }}>
              {dummyConpanies.map((data, i) => {
                return (
                  <Collapse.Panel
                    header={
                      <div style={{ height: '100%' }}>
                        {data.groupName}
                        <Typography.Paragraph style={{ float: 'right' }}>
                          {data.companies.length} Company
                        </Typography.Paragraph>
                      </div>
                    }
                    key={i}
                  >
                    <Row align='middle' gutter={16}>
                      {data.companies.map((company, j) => {
                        return (
                          <Col span={6} style={{ marginBottom: '1%' }}>
                            <div
                              style={
                                selectedCompany[0] === i &&
                                selectedCompany[1] === j
                                  ? { border: '3px solid #0957C2' }
                                  : {}
                              }
                              onClick={() => setSelectedCompany([i, j])}
                            >
                              <img
                                alt='icon'
                                src={company.url}
                                style={{ height: '80px', width: '100%' }}
                              />
                              <Typography.Paragraph>
                                {company.name}, {company.city}
                              </Typography.Paragraph>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </div>
          <div style={{ position: 'absolute', bottom: '5px' }}>
            <Divider />
            <Row style={{ alignItems: 'center' }}>
              <Button
                style={{
                  color: 'black',
                  width: '342px',
                  height: '44px',
                  background: '#FFFFFF',
                  margin: '10px',
                }}
                onClick={() => {
                  setCurrentStep(1);
                  setStepTwoColor('#FFFFFF');
                  setStepTwoFontColor('#000000');
                }}
              >
                Back
              </Button>
              <Button
                style={{
                  background: '#0957C2',
                  color: 'white',
                  width: '342px',
                  height: '44px',
                  margin: '10px',
                }}
              >
                Confirm Selection
              </Button>
            </Row>
          </div>
        </React.Fragment>
      )}
    </AuthLayout>
  );
};

export default CompanySelection;
