import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Tag,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomForm from '../../components/CustomForm';
import CompanySvc from '../../services/CompanySvc';
import GroupSvc from '../../services/GroupSvc';
import { CompanyProps, initialCompany } from '../../types/company.type';
import { IGroupCompanyData } from '../../types/groupCompany.type';
import { ContainerContent } from '../layout/AppLayout';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useFetchList from '../../hooks/useFetchList';
import { INITIAL_QUERY } from '../../helpers/pagination';
import DrawerDetail from '../../components/DrawerDetail/DrawerDetail';
import { GroupActionButton, GroupFormItem } from '../groupCompany';
import { baseUrl, httpRequest } from '../../helpers/api';

interface IQueryCompany {
  findField?: string;
  findValue?: string;
}

const Company = () => {
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] =
    useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<any>();
  const [imageData, setImageData] = useState<any>(null);
  useState<boolean>(false);
  const [isOpenCompanyDetail, setIsOpenCompanyDetail] =
    useState<boolean>(false);
  const [companyDetail, setCompanyDetail] = useState<CompanyProps>({
    ...initialCompany,
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const navigate = useNavigate();
  const [groups, setGroups] = useState<IGroupCompanyData[]>([]);
  const [form] = Form.useForm();
  const { handlePermission } = useAuth();

  const {
    query,
    isLoading,
    data,
    pagination,
    setSearch,
    setQuery,
    changePage,
    fetchList,
  } = useFetchList<CompanyProps, IQueryCompany>({
    endpoint: '/master/company',
    initialQuery: {
      page: 1,
      max: INITIAL_QUERY.max,
    },
  });

  const [isLoadingGroups, setLoadingGroups] = useState<boolean>(false);
  const [isLoadingAction, setLoadingAction] = useState<boolean>(false);

  const fetchGroups = async () => {
    try {
      setLoadingGroups(true);
      const resp = await GroupSvc.getGroups({
        params: {
          current: 1,
          pageSize: 1000,
          totalSize: 0,
        },
      });
      setGroups(resp?.data?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGroups(false);
    }
  };

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchGroups();
  }, []);

  useEffect(() => {
    if (isEditCompanyModalOpen === false) {
      form.resetFields();
      setIsEdit(false);
      setImageData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditCompanyModalOpen]);

  const handleDeleteCompany = async (compId: string, compName: string) => {
    setLoadingAction(true);
    try {
      await CompanySvc.deleteCompany(compId);
      message.success('Success delete data ' + compName);
    } catch (error) {
      message.error('Error: ' + error);
    } finally {
      setLoadingAction(false);
    }
  };

  const handleCreateCompany = async (values: CompanyProps) => {
    try {
      const formData = {
        compAddress: values?.compAddress,
        compGrcpId: values?.compGrcpId,
        compName: values?.compName,
        compPIC: values?.compPIC,
        compPICPhone: values?.compPICPhone,
        compPhone: values?.compPhone,
        compStatus: values?.compStatus,
      };
      await CompanySvc.addCompany(formData);

      let imageFormData = new FormData();
      imageFormData.append('image', imageData);

      await httpRequest.post(
        '/master/company/upload/' + companyDetail.compId,
        imageFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      message.success('Success add data ' + formData.compName);
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const handleEditCompany = async (values: CompanyProps) => {
    try {
      const formData = {
        compAddress: values?.compAddress,
        compGrcpId: values?.compGrcpId,
        compName: values?.compName,
        compPIC: values?.compPIC,
        compPICPhone: values?.compPICPhone,
        compPhone: values?.compPhone,
        compStatus: values?.compStatus,
      };
      await CompanySvc.updateCompany(companyDetail.compId, formData);

      let imageFormData = new FormData();
      imageFormData.append('image', imageData);

      await httpRequest.post(
        '/master/company/upload/' + companyDetail.compId,
        imageFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      message.success('Success update data ' + formData.compName);
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const handleMenuClick = (item: any, companyData: CompanyProps) => {
    setCompanyDetail(companyData);

    if (item === 'detail') {
      setIsOpenCompanyDetail(true);
      // navigate(`/company/${companyData.compId}`, {
      //   state: {
      //     compName: `${companyData.compName} Detail`,
      //     compId: companyData.compId,
      //   },
      // });
    } else if (item === 'edit') {
      setIsEdit(true);
      setIsEditCompanyModalOpen(true);
      form.setFieldsValue(companyData);
    } else if (item === 'delete') {
      confirmDelete(companyData.compId, companyData.compName);
    }
  };

  async function handleSubmitForm() {
    setLoadingAction(true);
    try {
      const values = await form.validateFields();
      if (values) {
        if (isEdit) {
          await handleEditCompany(values);
        } else {
          await handleCreateCompany(values);
        }
        handleCancelForm();
        fetchList();
      }
    } catch (error) {
      console.error('failed submit company', error);
    } finally {
      setLoadingAction(false);
    }
  }

  function handleCancelForm() {
    setIsEditCompanyModalOpen(false);
  }

  const confirmDelete = (compId: string, compName: string) => {
    Modal.confirm({
      title: 'Delete Company',
      content: `Are you sure want to delete "${compName}"?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDeleteCompany(compId, compName);
      },
      afterClose: async () => {
        setQuery((currQuery) => ({
          ...currQuery,
          page: 1,
        }));
      },
    });
  };

  const columns: ColumnsType<CompanyProps> = [
    {
      title: 'Flag',
      dataIndex: 'compImage',
      render: (imageUrl: string, record: CompanyProps) =>
        record.compImage || imageUrl ? (
          <CompanyFlag isThumbnail flagUrl={`${baseUrl}/${imageUrl}`} />
        ) : (
          <CompanyFlag isThumbnail flagUrl={'/images/logo-not-found.png'} />
        ),
    },
    {
      title: 'Name',
      dataIndex: 'compName',
      render: (initial: string, record: CompanyProps) => (
        <div
          style={
            handlePermission(['master_group_company', 'master_company'], 'read')
              ? {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#0957C2',
                }
              : {}
          }
          onClick={() => {
            if (
              handlePermission(
                ['master_group_company', 'master_company'],
                'read'
              )
            ) {
              handleMenuClick('detail', record);
            }
          }}
        >
          {initial}
        </div>
      ),
    },
    {
      title: 'Group',
      dataIndex: 'grcpCompId',
      render: (_, record: CompanyProps) => {
        return record.compGrcp ? (
          record.compGrcp.grcpName
        ) : (
          <DataTableEmpty>Not set</DataTableEmpty>
        );
      },
    },
    {
      title: 'Asset',
      dataIndex: 'assetCount',
      render: (_, record: CompanyProps) => {
        return record.compAsset ? (
          record.compAsset.length
        ) : (
          <DataTableEmpty>Not set</DataTableEmpty>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'compStatus',
      render: (compStatus: string) => (
        <Tag color={compStatus === '1' ? '#55C95A' : '#FE4545'}>
          {compStatus === '1' ? 'ACTIVE' : 'INACTIVE'}
        </Tag>
      ),
    },
    {
      title: 'PIC Name',
      dataIndex: 'compPIC',
      render: (compPIC: string) =>
        compPIC || <DataTableEmpty>Not set</DataTableEmpty>,
    },
    {
      title: 'Updated at',
      dataIndex: 'compUpdatedTime',
      render: (date: Date) => <span>{moment(date).format('D MMM YYYY')}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: CompanyProps) => {
        return (
          <GroupActionButton>
            {handlePermission(
              ['master_group_company', 'master_company'],
              'update'
            ) && (
              <Button
                onClick={() => handleMenuClick('edit', record)}
                size='small'
                value='edit'
              >
                Edit
              </Button>
            )}
            {handlePermission(
              ['master_group_company', 'master_company'],
              'delete'
            ) && (
              <Button
                onClick={() => handleMenuClick('delete', record)}
                size='small'
                danger
                value='delete'
              >
                Delete
              </Button>
            )}
          </GroupActionButton>
        );
      },
    },
  ];

  function setNewQuery(e: string, field: string) {
    if (e !== 'all') {
      setQuery({
        ...query,
        page: 1,
        findField: field,
        findValue: e,
      });
    } else {
      setQuery({
        page: 1,
        max: INITIAL_QUERY.max,
      });
    }
  }

  function handleAddCompany() {
    setIsEditCompanyModalOpen(true);
  }

  const groupMapped = useMemo(() => {
    const newGroups = [
      {
        label: 'All',
        value: 'all',
      },
    ];
    groups.forEach((item) =>
      newGroups.push({
        label: item.grcpName,
        value: item.grcpId,
      })
    );

    return newGroups;
  }, [groups]);

  return (
    <ContainerContent>
      <FilterContainer>
        <CustomForm label='Search'>
          <Input
            style={{ width: 300 }}
            placeholder='Search by company name'
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) =>
              setSearch({
                findField: 'compName',
                findValue: e.target.value,
              })
            }
          />
        </CustomForm>
        <CustomForm label='Status'>
          <Select
            defaultValue='all'
            style={{ width: 150 }}
            onChange={(e) => setNewQuery(e, 'compStatus')}
            options={[
              {
                value: 'all',
                label: 'All',
              },
              {
                value: '1',
                label: 'Active',
              },
              {
                value: '0',
                label: 'Inactive',
              },
            ]}
          />
        </CustomForm>
        <CustomForm label='Group'>
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').toString().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toString()
                .toLowerCase()
                .localeCompare(
                  (optionB?.label.toLowerCase() ?? '').toLowerCase()
                )
            }
            defaultValue='all'
            placeholder='Select Group'
            onChange={(e) => setNewQuery(e, 'compGrcpId')}
            options={groupMapped}
          />
        </CustomForm>
        <div />
        {handlePermission(
          ['master_group_company', 'master_company'],
          'create'
        ) && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={handleAddCompany}
          >
            Add Company
          </Button>
        )}
      </FilterContainer>

      <Table
        loading={isLoading}
        size='middle'
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <Pagination
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => changePage(page, perPage)}
      />

      {isOpenCompanyDetail && (
        <DrawerDetail
          initialTitle='Company Detail'
          initialState='asset'
          open={isOpenCompanyDetail}
          onClose={() => {
            setIsOpenCompanyDetail(false);
            setCompanyDetail({ ...initialCompany });
          }}
          detailCompany={companyDetail}
        />
      )}

      <Modal
        width={'45%'}
        title={isEdit ? 'Edit Company' : 'Add Company'}
        open={isEditCompanyModalOpen}
        destroyOnClose
        onCancel={handleCancelForm}
        className='custom-modal'
        footer={[
          <Button
            loading={isLoadingAction}
            style={{ background: '#FFFFFF', color: '#000000' }}
            onClick={handleCancelForm}
          >
            Cancel
          </Button>,
          <Button
            loading={isLoadingAction}
            disabled={
              !handlePermission(['master_company'], 'create') &&
              !handlePermission(['master_company'], 'update')
            }
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={handleSubmitForm}
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>,
        ]}
      >
        <Form layout='vertical' form={form} onFinish={handleSubmitForm}>
          <Typography.Paragraph>Company Flag</Typography.Paragraph>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}
          >
            {previewImage !== undefined ? (
              <img
                alt='preview_image'
                src={previewImage}
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            ) : companyDetail.compImage ? (
              <CompanyFlagPreview
                flagUrl={`${baseUrl}/${companyDetail.compImage}`}
              />
            ) : (
              <img
                alt='preview_image'
                src='/images/empty-image.png'
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            )}

            <div style={{ marginLeft: 24, flex: 1 }}>
              <label
                style={{
                  marginBottom: 12,
                  display: 'block',
                }}
              >
                <br />
                <input
                  type='file'
                  name='massImage'
                  accept='.jpg,.png,.jpeg'
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      setPreviewImage(undefined);
                      return;
                    }

                    if (e.target.files[0].size > 2000000) {
                      message.warn('Maximum fize size 2 Mb');
                      return;
                    }

                    const objectUrl = URL.createObjectURL(e.target.files[0]);
                    setImageData(e.target.files[0]);
                    setPreviewImage(objectUrl);
                  }}
                />
              </label>
              <Typography.Paragraph style={{ fontSize: 12, opacity: 0.6 }}>
                Max file size 2 MB. Extention accepted are jpg, png and jpeg.
                Recommended image 800x800px or 1:1 aspect ratio
              </Typography.Paragraph>
            </div>
          </div>

          <Form.Item
            label='Company Name'
            name='compName'
            rules={[{ required: true }]}
          >
            <Input name='compName' placeholder='Enter Company Name' />
          </Form.Item>

          <Form.Item
            label='Group'
            name='compGrcpId'
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').toString().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label.toLowerCase() ?? '').toLowerCase()
                  )
              }
              disabled={isLoadingGroups}
              placeholder='Select Group'
              options={groups?.map((group, index) => ({
                value: group.grcpId,
                label: `(${group.grcpNickName}) ${group.grcpName}`,
              }))}
            ></Select>
          </Form.Item>

          <Form.Item label='Address' name='compAddress'>
            <Input placeholder='Enter Address' />
          </Form.Item>

          <GroupFormItem child={2}>
            <Form.Item
              label='Status'
              name='compStatus'
              rules={[{ required: true }]}
            >
              <Select
                placeholder='Select Status'
                options={['1', '0'].map((item) => ({
                  value: item,
                  label: item === '1' ? 'Active' : 'Inactive',
                }))}
              ></Select>
            </Form.Item>

            <Form.Item name='compPhone' label='Company Phone Number'>
              <Input placeholder='Enter Company Phone' />
            </Form.Item>
          </GroupFormItem>

          <GroupFormItem child={2}>
            <Form.Item label='PIC Name' name='compPIC'>
              <Input placeholder='Enter PIC Name' />
            </Form.Item>

            <Form.Item name='compPICPhone' label='PIC Phone Number'>
              <Input placeholder='Enter PIC Phone Number' />
            </Form.Item>
          </GroupFormItem>

          {/* <Typography.Paragraph>Application</Typography.Paragraph>
          <Row gutter={16}>
            <Col span={12}>
              <Checkbox
                onChange={() => {
                  let x = { ...selectedApplication };
                  x["fuelManagementSystem"] =
                    x["fuelManagementSystem"] === false ? true : false;
                  setSelectedApplication(x);
                }}
              >
                Fuel Monitoring System
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                onChange={() => {
                  let x = { ...selectedApplication };
                  x["assetManagement"] =
                    x["assetManagement"] === false ? true : false;
                  setSelectedApplication(x);
                }}
              >
                Asset Monitoring
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                onChange={() => {
                  let x = { ...selectedApplication };
                  x["bunkeringManagement"] =
                    x["bunkeringManagement"] === false ? true : false;
                  setSelectedApplication(x);
                }}
              >
                Bunkering Monitoring
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                onChange={() => {
                  let x = { ...selectedApplication };
                  x["tankLevelManagement"] =
                    x["tankLevelManagement"] === false ? true : false;
                  setSelectedApplication(x);
                }}
              >
                Tank Level Monitoring
              </Checkbox>
            </Col>
          </Row> */}
        </Form>
      </Modal>
    </ContainerContent>
  );
};

export default Company;

type CompanyFlagProps = {
  flagUrl?: string;
  isThumbnail?: boolean;
};

const CompanyFlag = styled.div<CompanyFlagProps>`
  height: ${(props) => (props.isThumbnail ? '40px' : '40px')};
  width: ${(props) => (props.isThumbnail ? '40px' : '40px')};
  background: url(${({ flagUrl }) => flagUrl});
  border: 1px solid #c5cddb;
  border-radius: 3px;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
`;

const CompanyFlagPreview = styled.div<CompanyFlagProps>`
  height: 100px;
  width: 100px;
  background: url(${({ flagUrl }) => flagUrl});
  border: 1px solid #c5cddb;
  border-radius: 3px;
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const DataTableEmpty = styled.div`
  opacity: 0.5;
  font-style: italic;
`;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 300px 150px 300px 1fr auto;
  padding: 0px 0px 16px;
`;
