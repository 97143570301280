import styled from 'styled-components';
import { Button, Modal, Pagination, Select, Table, message } from 'antd';
import CustomForm from '../../../components/CustomForm';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import useFetchList from '../../../hooks/useFetchList';
import { INITIAL_QUERY } from '../../../helpers/pagination';
import { IReportTargetAsset } from '../../../types/masterReport.type';
import { useEffect, useMemo, useRef, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { GroupActionButton } from '../../groupCompany';
import {
  MasterReportTemplateSvc,
  MasterTargetAssetSvc,
} from '../../../services/MasterReportSvc';
import useCompany from '../../../hooks/useCompany';
import ModalMasterTargetAsset from './modalMasterReportTargetAsset';
import useAsset from '../../../hooks/useAsset';
import AuthSvc from '../../../services/AuthSvc';
import AssetSvc from '../../../services/AssetSvc';
import { Trash2 } from 'react-feather';

interface IQueryTargetAssetReceivers {
  findField?: string;
  findValue?: string;
  mrtaCompId?: string;
  mrtaMassId?: string;
}

export interface IGroupTargetByTemplate {
  templateId: string;
  templateName: string;
  datas: IReportTargetAsset[];
}

interface IDataTargetAsset {
  key: string;
  massId: string;
  massCompId: string;
  massName: string;
  massType: string;
  massSystemType: string;
  targets: IGroupTargetByTemplate[];
}

const MasterTargetAsset: React.FC<{
  handleSetSearchParams: (value: string) => void;
  activeCompId: string;
}> = (props) => {
  const [isOpenModalMasterTargetAsset, setIsOpenModalMasterTargetAsset] =
    useState<boolean>(false);
  const [targetAssetDetail, setTargetAssetDetail] =
    useState<IGroupTargetByTemplate>();
  const [selectedCompId, setSelectedCompId] = useState<string>();
  const [selectedAssetId, setSelectedAssetId] = useState<string>();
  const [localAssets, setLocalAssets] = useState<any[]>([]);
  const {
    activeCompany,
    availableCompanies,
    setAvailableCompaniesRaw,
    setCurrentActiveCompany,
  } = useCompany();
  const dataFetchedRef = useRef(false);
  const { assets2 } = useAsset();
  const { handlePermission, loginData } = useAuth();
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false);
  const [dataTarget, setDataTarget] = useState<IDataTargetAsset[]>([]);

  const {
    query,
    isLoading,
    data,
    pagination,
    setSearch,
    changePage,
    fetchList,
    setQuery,
  } = useFetchList<IReportTargetAsset, IQueryTargetAssetReceivers>({
    fetchable: false,
    endpoint: '/master/report_target_asset',
    initialQuery: {
      page: 0,
      max: INITIAL_QUERY.max,
    },
  });

  function setNewQuery(e: string, field: string) {
    if (e !== 'all') {
      setQuery({
        ...query,
        page: 1,
        findField: field,
        findValue: e,
      });
    } else {
      setQuery({
        page: 1,
        max: INITIAL_QUERY.max,
      });
    }
  }

  async function handleAfterSubmit() {
    setIsOpenModalMasterTargetAsset(false);
    setTargetAssetDetail(undefined);
    await fetchLocalAsset();
  }

  const handleDeleteTargetAsset = async (mrtaIds: string[]) => {
    try {
      for (let i = 0; i < mrtaIds.length; i++) {
        const mrtaId = mrtaIds[i];
        await MasterTargetAssetSvc.deleteTargetAsset(mrtaId);
      }
      if (mrtaIds.length > 1) {
        message.success('Success delete multiple target emails');
      } else {
        message.success('Success delete target email');
      }
    } catch (error) {
      message.error('Error: ' + error);
    } finally {
      setTargetAssetDetail(undefined);
      await fetchLocalAsset();
    }
  };

  const confirmDelete = (mrtaIds: string[]) => {
    Modal.confirm({
      title: 'Delete Target Asset',
      content: `Are you sure want to delete this targets?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDeleteTargetAsset(mrtaIds);
      },
      afterClose: async () => {
        setQuery((currQuery) => ({
          ...currQuery,
          page: 1,
        }));
      },
    });
  };

  const handleMenuClick = (item: any, data: IGroupTargetByTemplate) => {
    setTargetAssetDetail(data);
    if (item === 'edit') {
      setIsOpenModalMasterTargetAsset(true);
    } else if (item === 'delete') {
      const deletedMrtaIds = data.datas.map((item) => item.mrtaId);
      confirmDelete(deletedMrtaIds);
    }
  };

  const localAssetMapped = useMemo(() => {
    const newLocalAssets = localAssets.map((item) => ({
      value: item.massId,
      label: item.massName,
    }));
    // return [{ value: 'all', label: 'All Asset' }, ...newLocalAssets];
    return newLocalAssets;
  }, [localAssets]);

  async function fetchTargetByAsset(mrtaMassId: string) {
    try {
      const response = await MasterTargetAssetSvc.getAllTargetAsset({
        findField: 'mrtaMassId',
        findValue: mrtaMassId,
      });

      return response.data.data;
    } catch (error) {
      console.error('failed fetch data target');
    }
  }

  async function fetchLocalAsset() {
    setLoadingAssets(true);
    try {
      const assetsResponse = await AssetSvc.getAssets();
      if (
        assetsResponse.data &&
        assetsResponse.data.data &&
        assetsResponse.data.data.length > 0
      ) {
        const response = assetsResponse.data.data;
        setLocalAssets(response);
        setSelectedAssetId(response[0].massId);

        const targetByAssets = response.map(async (item) => {
          const results = await fetchTargetByAsset(item.massId);
          return {
            ...item,
            targets: results,
          };
        });

        const newDataAssets = await Promise.all(targetByAssets);
        const newDataAssetsWithTarget = newDataAssets.map((item) => {
          let targets: Array<IGroupTargetByTemplate> = [];

          if (item.targets && item.targets.length > 0) {
            targets = item.targets?.reduce((acc, curr) => {
              const findIndex = acc.findIndex(
                (item: any) => item.templateId === curr.mrtaMrtpId
              );

              if (findIndex > -1) {
                acc[findIndex]?.datas.push(curr);
              } else {
                acc.push({
                  templateId: curr.mrtaMrtpId,
                  templateName: curr.mrtaMrtp.mrtpName,
                  datas: [curr],
                });
              }

              return acc;
            }, [] as Array<IGroupTargetByTemplate>);
          }

          return {
            key: item.massId,
            massId: item.massId,
            massCompId: item.massCompId,
            massName: item.massName,
            massType: item.massType,
            massSystemType: item.massSystemType,
            targets: targets || [],
          };
        });
        setDataTarget(newDataAssetsWithTarget);
      }
    } catch (error) {
      console.error('failed get current assets', error);
    } finally {
      setLoadingAssets(false);
    }
  }

  async function onChangeCompany(compId: string) {
    setSelectedCompId(compId);
    // setNewQuery(compId, 'mrtaCompId');
    props.handleSetSearchParams(compId);

    setLoadingCompany(true);
    try {
      await AuthSvc.setCompany(compId);
      const assetsResponse = await AssetSvc.getAssets();

      if (assetsResponse.data) {
        setLocalAssets(assetsResponse.data.data);
        // setSelectedCompId(compId);
        setSelectedAssetId(assetsResponse.data.data[0].massId);
        // setQuery({
        //   page: 1,
        //   max: INITIAL_QUERY.max,
        //   mrtaMassId: assetsResponse.data.data[0].massId,
        // });
        setNewQuery(assetsResponse.data.data[0].massId, 'mrtaMassId');
        await setCurrentActiveCompany({
          compId,
          setCompany: true,
        });
        await fetchLocalAsset();
      }
    } catch (error) {
      console.error('failed get company', error);
    } finally {
      setLoadingCompany(false);
    }
  }

  useEffect(() => {
    if (props.activeCompId) {
      setSelectedCompId(props.activeCompId);
      onChangeCompany(props.activeCompId);
    }
  }, [props.activeCompId]);

  useEffect(() => {
    if (loginData && loginData.company && loginData.company.available) {
      setAvailableCompaniesRaw(loginData.company.available);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  function convertFrequencyType(current: string) {
    switch (current) {
      case '1':
        return 'HOURLY';
      case '2':
        return 'DAILY';
      case '3':
        return 'WEEKLY';
      case '4':
        return 'MONTHLY';

      default:
        return '-';
    }
  }

  const columns: ColumnsType<IDataTargetAsset> = [
    {
      key: 'massName',
      title: 'Asset',
      dataIndex: 'massName',
      render: (_, record) => {
        return record.massName;
      },
    },
    {
      key: 'targets',
      title: 'Report Type',
      dataIndex: 'targets',
      render: (_, record: IDataTargetAsset) => {
        return record.targets.length;
      },
    },
  ];

  async function handleMenuDeleteTarget(mrtaId: string) {
    await handleDeleteTargetAsset([mrtaId]);
  }

  const columnsTargetByTemplate: ColumnsType<IReportTargetAsset> = [
    {
      width: 380,
      key: 'mrtaMrth',
      title: 'Email',
      dataIndex: 'mrtaMrth',
      render: (_, record) => {
        return record.mrtaMrth.mrthEmail;
      },
    },
    {
      key: 'frequency',
      title: 'Frequency',
      dataIndex: 'frequency',
      render: (_, record: IReportTargetAsset) => {
        return convertFrequencyType(record.mrtaFrequencyType);
      },
    },
    {
      key: 'mrtaTime',
      title: 'Time',
      dataIndex: 'mrtaTime',
    },
    {
      key: 'mrtaRange',
      title: 'Range Data',
      dataIndex: 'mrtaRange',
      render: (_, record: IReportTargetAsset) => {
        return record.mrtaTimeFrom + ' - ' + record.mrtaTimeTo;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: IReportTargetAsset) => {
        return (
          <GroupActionButton>
            {handlePermission(['master_report_target_asset'], 'delete') && (
              <Button
                onClick={() => handleMenuDeleteTarget(record.mrtaId)}
                size='small'
                // danger
                type='ghost'
                style={{
                  lineHeight: 2,
                }}
                icon={<Trash2 size={16} color='red' />}
              ></Button>
            )}
          </GroupActionButton>
        );
      },
    },
  ];

  const _renderExpandRow = (record: IGroupTargetByTemplate[]) => {
    if (record.length === 0) {
      return <div>Data target email empty</div>;
    }

    return (
      <div>
        {record.map((item) => (
          <div key={item.templateId} style={{ marginBottom: 15 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span>Template Name: </span>
                <div style={{ marginLeft: 15 }}>
                  <b>{item.templateName}</b>
                </div>
              </div>
              <GroupActionButton style={{ width: 210 }}>
                {handlePermission(['master_report_target_asset'], 'update') && (
                  <Button
                    onClick={() => handleMenuClick('edit', item)}
                    size='small'
                    value='edit'
                  >
                    Edit Target
                  </Button>
                )}
                {handlePermission(['master_report_target_asset'], 'delete') && (
                  <Button
                    onClick={() => handleMenuClick('delete', item)}
                    size='small'
                    danger
                    value='delete'
                  >
                    Delete All Target
                  </Button>
                )}
              </GroupActionButton>
            </div>
            <Table
              size='small'
              columns={columnsTargetByTemplate}
              dataSource={item.datas}
              pagination={false}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <ContainerContentMasterReport>
      <FilterContainer>
        {/* <CustomForm label='Search'>
          <Input
            placeholder='Search by email'
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) =>
              setSearch({
                findField: 'mrtaEmail',
                findValue: e.target.value,
              })
            }
          />
        </CustomForm> */}
        {handlePermission(['master_company']) && (
          <CustomForm label='Company'>
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').toString().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label.toLowerCase() ?? '').toLowerCase()
                  )
              }
              value={selectedCompId}
              onChange={onChangeCompany}
              options={
                availableCompanies
                  ? availableCompanies.map((item) => ({
                      value: item.compId,
                      label: item.compName,
                    }))
                  : []
              }
            />
          </CustomForm>
        )}
        <div />
        {handlePermission(['master_report_target_asset'], 'create') && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={() => {
              setIsOpenModalMasterTargetAsset(true);
            }}
          >
            Add Target Email
          </Button>
        )}
      </FilterContainer>

      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataTarget}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => _renderExpandRow(record.targets),
        }}
      />

      <Pagination
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => changePage(page, perPage)}
      />

      {selectedCompId && (
        <ModalMasterTargetAsset
          isOpen={isOpenModalMasterTargetAsset}
          data={targetAssetDetail}
          afterSubmit={handleAfterSubmit}
          compId={selectedCompId}
          assets={localAssetMapped}
        />
      )}
    </ContainerContentMasterReport>
  );
};

const ContainerContentMasterReport = styled.div`
  padding: 0 20px;
  background-color: white;
`;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 250px 1fr auto;
  padding: 0px 0px 15px;
`;

export default MasterTargetAsset;
