import { Button, Select } from 'antd';
import styled from 'styled-components';
import CustomForm from '../../../components/CustomForm';
import AuthSvc from '../../../services/AuthSvc';
import { AssetType } from '../../../types/asset.type';
import { intervalOptions, rangeOptions } from '../../../helpers/map';
import moment, { Moment } from 'moment';
import useFilter from '../../../hooks/useFilter';
import useCompany from '../../../hooks/useCompany';
import { useEffect, useMemo, useState } from 'react';
import AssetSvc from '../../../services/AssetSvc';
import useAsset from '../../../hooks/useAsset';
import { DataFilterProps, initialDataFilter } from '../../../types/config.type';
import { useLocation } from 'react-router';
import useMapConfig from '../../../hooks/useMapConfig';
import useAuth from '../../../hooks/useAuth';
import DateRangePicker from '../../../components/DateRangePicker';
import {
  setCurrentTimezone,
  timezoneConstanta,
} from '../../../helpers/dateUtil';
import { getTimezone } from '../../../helpers/asset';

interface Props {
  setURLParams: (params: string[][]) => void;
  searchParams: URLSearchParams;
  isLog?: boolean;
  isHomeSummaryTable?: boolean;
  resetSearchParams?: () => void;
}

const FilterDashboard: React.FC<Props> = (props) => {
  const { isLoadingFilter, setDataFilter, dataFilter } = useFilter();
  const {
    isLoadingAssetSummary,
    assets2,
    fetchAssetsWithLastData,
    currentDataSource,
    currentDataTimezone,
  } = useAsset();
  const [localFilter, setLocalFilter] = useState<DataFilterProps>({
    ...initialDataFilter,
  });
  const [localAsssts, setLocalAssets] = useState<AssetType[]>([]);
  const {
    availableCompanies,
    activeCompany,
    setCurrentActiveCompany,
    isLoadingCompanyData,
    setAvailableCompaniesRaw,
  } = useCompany();
  const { showTableSummary } = useMapConfig();
  const location = useLocation();
  const { loginData } = useAuth();

  // const defaultToHour = localFilter.massId === '42' || props.isHomeSummaryTable;
  const defaultToHour =
    props.isHomeSummaryTable || currentDataSource === 'satellite';

  useEffect(() => {
    if (loginData && loginData.company && loginData.company.available) {
      setAvailableCompaniesRaw(loginData.company.available);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData.company]);

  const constanta = useMemo(() => {
    return timezoneConstanta(currentDataTimezone);
  }, [currentDataTimezone]);

  const timezone = useMemo(() => {
    return setCurrentTimezone(currentDataTimezone);
  }, [currentDataTimezone]);

  useEffect(() => {
    if (activeCompany && assets2.length > 0) {
      const searchParams = new URLSearchParams(document.location.search);
      const queryMassId = searchParams.get('massId');
      const queryCompId = searchParams.get('compId');
      const queryStart = searchParams.get('start');
      const queryEnd = searchParams.get('end');
      const queryAggregatedUnit = searchParams.get('aggregatedUnit');
      const queryRangeType = searchParams.get('value');
      const selectedTimezone = getTimezone();

      // const initialStart = moment().utcOffset(String(selectedTimezone * 60)).set({
      //   hours: 0,
      //   minutes: 0,
      //   seconds: 0,
      //   milliseconds: 0,
      // });
      // const initialEnd = moment().utcOffset(String(selectedTimezone * 60));

      const initialStart = moment().tz(timezone).set({
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      const initialEnd = moment().tz(timezone).set({
        seconds: 0,
        milliseconds: 0,
      });

      let startAt: any;
      let endAt: any;

      if (queryStart) {
        startAt = moment(Number(queryStart)).tz(timezone);
        // .subtract(constanta, 'hour');
      } else {
        startAt = moment(initialStart).tz(timezone);
        // .subtract(constanta, 'hour');
      }

      if (queryEnd) {
        endAt = moment(Number(queryEnd)).tz(timezone);
      } else {
        endAt = moment(initialEnd).tz(timezone);
      }

      setLocalFilter((old) => ({
        ...old,
        massId: queryMassId || localFilter.massId || assets2[0].massId,
        compId: queryCompId || localFilter.compId || activeCompany.compId,
        interval: queryAggregatedUnit
          ? queryAggregatedUnit
          : defaultToHour
          ? intervalOptions[1].value // set default interval to hour
          : intervalOptions[0].value,
        rangeType: queryRangeType || rangeOptions[4].value,
        range: {
          startAt,
          endAt,
        },
      }));

      setDataFilter((current) => ({
        ...current,
        range: {
          startAt,
          endAt,
        },
        massId: queryMassId || assets2[0].massId,
        compId: queryCompId || activeCompany.compId,
        interval: queryAggregatedUnit
          ? queryAggregatedUnit
          : defaultToHour
          ? intervalOptions[1].value // set default interval to hour
          : intervalOptions[0].value,
      }));

      let currAssets2: AssetType[] = [];
      if (location.pathname.includes('home') && showTableSummary) {
        currAssets2 = assets2.filter(
          (item) =>
            item.dataSummary &&
            item.dataSummary?.gps &&
            item.dataSummary?.gps.data.length > 0
        );
      } else {
        currAssets2 = [...assets2];
      }

      // setLocalAssets(assets2);
      setLocalAssets(currAssets2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany, assets2, showTableSummary]);

  const onChangeCompany = async (compId: string) => {
    await AuthSvc.setCompany(compId);
    const assetsResponse = await AssetSvc.getAssets();

    if (assetsResponse.data && assetsResponse.data.data) {
      // Filter if data gps exists
      setLocalAssets(assetsResponse.data.data);
      setLocalFilter((current) => ({
        ...current,
        compId,
        massId: assetsResponse.data.data[0].massId,
      }));
    }
  };

  const onChangeAsset = async (assetId: string) => {
    setLocalFilter((current) => ({ ...current, massId: assetId }));
  };

  const onChangeRangeType = (value: string) => {
    let rangeType: any;

    const found = rangeOptions.find((item) => item.value === value);

    if (found) {
      rangeType = found.value;
    }

    let interval = localFilter.interval;
    let startAt = moment().tz(timezone);
    let endAt = moment().tz(timezone);
    if (value === 'today' || value === 'last_day') {
      interval = intervalOptions[0].value;
    } else {
      interval = intervalOptions[2].value;
    }
    switch (value) {
      case 'last_days_30':
        startAt = moment().tz(timezone).subtract(30, 'day');
        endAt = moment().tz(timezone);
        break;
      case 'last_month':
        startAt = moment()
          .tz(timezone)
          .subtract(1, 'month')
          .startOf('month')
          .set({ hour: 0, minute: 0, second: 0 });
        endAt = moment()
          .tz(timezone)
          .subtract(1, 'month')
          .endOf('month')
          .set({ hour: 23, minute: 59, second: 59 });
        break;
      case 'last_days_7':
        startAt = moment().tz(timezone).subtract(7, 'days');
        endAt = moment().tz(timezone);
        break;
      case 'last_week':
        startAt = moment().tz(timezone).subtract(1, 'week').startOf('week');
        endAt = moment().tz(timezone);
        break;
      case 'today':
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        startAt = moment().tz(timezone).set({ hour: 0, minute: 0, second: 0 });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        endAt = moment().tz(timezone);
        break;

      default:
        break;
    }
    setLocalFilter((current) => ({
      ...current,
      rangeType,
      range: {
        startAt,
        endAt,
      },
      interval,
    }));
  };

  const onChangeCustomTime = (date: Moment, type: string) => {
    setLocalFilter((current) => {
      const currDateTime = moment(
        type === 'start' ? current.range.startAt : current.range.endAt
      ).set({
        hours: date.get('hours'),
        minutes: date.get('minutes'),
        seconds: 0,
        milliseconds: 0,
      });

      return {
        ...current,
        rangeType: rangeOptions[6].value,
        range: {
          startAt: type === 'start' ? currDateTime : current.range.startAt,
          endAt: type === 'end' ? currDateTime : current.range.endAt,
        },
      };
    });
  };

  function onChangeCustomRange(date: any) {
    setLocalFilter((current) => {
      let startAt = current.range.startAt as Moment;
      let endAt = current.range.endAt as Moment;

      const systemTimezone = (new Date().getTimezoneOffset() / 60) * -1;

      startAt = moment(date[0].valueOf())
        .tz(timezone, systemTimezone !== 8)
        .set({
          hour: moment(current.range.startAt).get('hour'),
          minute: moment(current.range.startAt).get('minute'),
          second: moment(current.range.startAt).get('second'),
        });

      endAt = moment(date[1].valueOf())
        .tz(timezone, systemTimezone !== 8)
        .set({
          hour: moment(current.range.endAt).get('hour'),
          minute: moment(current.range.endAt).get('minute'),
          second: moment(current.range.endAt).get('second'),
        });

      return {
        ...current,
        rangeType: rangeOptions[6].value,
        range: {
          startAt,
          endAt,
        },
      };
    });
  }

  const onChangeInterval = (interval: string) => {
    setLocalFilter((oldVal) => ({
      ...oldVal,
      interval,
    }));
  };

  const isLoadingData = useMemo(() => {
    if (isLoadingFilter || isLoadingCompanyData || isLoadingAssetSummary) {
      return true;
    }
    return false;
  }, [isLoadingFilter, isLoadingCompanyData, isLoadingAssetSummary]);

  const handleOnApplyFilter = async () => {
    try {
      const startAt = moment(localFilter.range.startAt).set({
        seconds: 0,
        milliseconds: 0,
      });
      const endAt = moment(localFilter.range.endAt).set({
        seconds: 0,
        milliseconds: 0,
      });

      setDataFilter({
        ...localFilter,
        range: {
          startAt,
          endAt,
        },
      });

      const queryParams: string[][] = [];
      let interval: string = localFilter.interval;

      if (defaultToHour) {
        interval = intervalOptions[1].value; // set default interval to hour
      }

      queryParams.push(
        ['start', startAt.valueOf().toString()],
        ['end', endAt.valueOf().toString()],
        ['mode', 'period'],
        ['value', localFilter.rangeType],
        ['aggregatedUnit', interval],
        ['massId', localFilter.massId],
        ['compId', localFilter.compId]
      );
      props.setURLParams(queryParams);
      setCurrentActiveCompany({
        compId: localFilter.compId,
        setCompany: true,
        getCompany: true,
      });
      await fetchAssetsWithLastData({
        start: localFilter.range.startAt.valueOf().toString(),
        end: localFilter.range.endAt.valueOf().toString(),
        devcMassId:
          showTableSummary && location.pathname.includes('home')
            ? undefined
            : localFilter.massId,
        aggregatedUnit:
          location.pathname.includes('/report-summary') &&
          localFilter.massId === '62'
            ? 'MINUTE' // pengkondisian untuk kapal citra 02 dan halaman summary agar tidak bisa mengambil data selain minute
            : localFilter.interval,
        aggregatedLength:
          location.pathname.includes('/report-data-log') ||
          location.pathname.includes('/report-bms-log') ||
          location.pathname.includes('/report-summary')
            ? 1
            : undefined,
      });
    } catch (error) {
      console.error('error handleApplyFilter', error);
    }
  };

  const newIntervalOptions = useMemo(() => {
    let currentIntervalOptions = intervalOptions;
    const diffEndStart = moment(localFilter.range.endAt).diff(
      moment(localFilter.range.startAt),
      'days'
    );

    if (
      location.pathname.includes('/report-summary') &&
      localFilter.massId === '62'
    ) {
      currentIntervalOptions = currentIntervalOptions.map((item) => {
        if (['HOUR', 'DAY', 'WEEK', 'MONTH'].includes(item.value)) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }
      });
    } else if (diffEndStart < 31 && diffEndStart > 6) {
      currentIntervalOptions = currentIntervalOptions.map((item) => {
        if (['MINUTE'].includes(item.value)) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }
      });

      setLocalFilter((current) => ({
        ...current,
        interval: 'DAY',
      }));
    } else if (diffEndStart > 6) {
      currentIntervalOptions = currentIntervalOptions.map((item) => {
        if (['MINUTE', 'HOUR'].includes(item.value)) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }
      });

      setLocalFilter((current) => ({
        ...current,
        interval: 'DAY',
      }));
    } else if (diffEndStart > 1 || currentDataSource === 'satellite') {
      currentIntervalOptions = currentIntervalOptions.map((item) => {
        if (['MINUTE'].includes(item.value)) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }
      });

      setLocalFilter((current) => ({
        ...current,
        interval: 'HOUR',
      }));
    }

    return currentIntervalOptions;
  }, [
    localFilter.massId,
    localFilter.rangeType,
    localFilter.range.startAt,
    localFilter.range.endAt,
    currentDataSource,
  ]);

  const isInvalidDateRange = useMemo(() => {
    const startTimestamp = moment(localFilter.range.startAt).valueOf();
    const endTimestamp = moment(localFilter.range.endAt).valueOf();

    return startTimestamp > endTimestamp ? true : false;
  }, [localFilter.range.startAt, localFilter.range.endAt]);

  return (
    <FilterWrapper
      isLastDay={localFilter.rangeType === 'last_day'}
      isHomeSummaryTable={props.isHomeSummaryTable}
    >
      {!props.isHomeSummaryTable && (
        <CustomForm label='Company'>
          <Select
            size='small'
            value={localFilter.compId}
            style={{ width: '100%' }}
            onChange={onChangeCompany}
            disabled={
              isLoadingData ||
              (availableCompanies && availableCompanies.length === 1)
            }
            options={
              availableCompanies &&
              availableCompanies.map((comp) => ({
                value: comp.compId,
                label: comp.compName,
              }))
            }
          />
        </CustomForm>
      )}
      <CustomForm label='Asset'>
        <Select
          size='small'
          value={localFilter.massId}
          style={{
            width:
              localFilter.rangeType === 'last_day' || props.isHomeSummaryTable
                ? '100%'
                : 140,
          }}
          onChange={onChangeAsset}
          disabled={isLoadingData}
          loading={isLoadingData}
          options={localAsssts.map((item) => ({
            value: item.massId,
            label: item.massName,
          }))}
        />
      </CustomForm>
      {!props.isHomeSummaryTable && (
        <CustomForm label='Range Type'>
          <Select
            size='small'
            value={localFilter.rangeType}
            style={{ width: 100 }}
            disabled={isLoadingData || localAsssts.length === 0}
            loading={isLoadingData}
            onChange={onChangeRangeType}
            options={rangeOptions}
          />
        </CustomForm>
      )}

      {localFilter.rangeType && localFilter.rangeType !== 'last_day' ? (
        <CustomForm
          label='Range'
          errorMessage='Filter date range invalid'
          error={isInvalidDateRange}
        >
          <DateRangePicker
            timezone={timezone}
            constanta={constanta}
            startAt={localFilter.range.startAt}
            endAt={localFilter.range.endAt}
            custom={true}
            disabled={isLoadingData || localAsssts.length === 0}
            onChange={(e) => console.log(e)}
            onChangeCustomTime={(e, type) => onChangeCustomTime(e, type)}
            onChangeCustomRange={(e) => onChangeCustomRange(e)}
          />
        </CustomForm>
      ) : (
        false
      )}
      <CustomForm label='Interval'>
        <Select
          size='small'
          value={localFilter.interval}
          style={{ width: 100 }}
          disabled={isLoadingData || localAsssts.length === 0}
          loading={isLoadingData}
          onChange={onChangeInterval}
          options={newIntervalOptions}
          // options={intervalOptions}
        />
      </CustomForm>
      <Button
        size='small'
        disabled={isInvalidDateRange}
        loading={isLoadingData}
        style={{ width: 80 }}
        onClick={handleOnApplyFilter}
        type='primary'
      >
        Apply
      </Button>
    </FilterWrapper>
  );
};

interface IFilterWrapper {
  isLastDay: boolean;
  isHomeSummaryTable?: boolean;
}

const FilterWrapper = styled.div<IFilterWrapper>`
  display: grid;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  grid-template-columns: ${(props) =>
    props.isHomeSummaryTable
      ? '1fr 400px 100px 120px'
      : props.isLastDay
      ? '1fr 1fr 1.5fr 100px 80px'
      : '1fr 140px 100px 1.5fr 100px 80px'};
  padding: 10px;
  /* height: 80px; */
`;

export default FilterDashboard;
