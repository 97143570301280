import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Select,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CardAssetItem from '../../components/CardAssetItem';
import CustomForm from '../../components/CustomForm';
import useCompany from '../../hooks/useCompany';
import AssetSvc from '../../services/AssetSvc';
import { IAssetData, initialAsset } from '../../types/asset.type';
import useAuth from '../../hooks/useAuth';
import { INITIAL_QUERY } from '../../helpers/pagination';
import useFetchList from '../../hooks/useFetchList';
import AuthSvc from '../../services/AuthSvc';
import DrawerDetail from '../../components/DrawerDetail/DrawerDetail';
import { GroupFormItem } from '../groupCompany';
import { baseUrl, httpRequest } from '../../helpers/api';
import moment from 'moment';
import useFilter from '../../hooks/useFilter';
import DeviceSvc from '../../services/DeviceSvc';

interface IQueryAsset {
  findField?: string;
  findValue?: string;
}

const Asset = () => {
  const { handlePermission } = useAuth();
  const [previewImage, setPreviewImage] = useState<any>();
  const [imageData, setImageData] = useState<any>(null);
  const [isEditAssetModalOpen, setIsEditAssetModalOpen] =
    useState<boolean>(false);
  const [isOpenAssetDetail, setIsOpenAssetDetail] = useState<boolean>(false);
  const [assetDetail, setAssetDetail] = useState<IAssetData>({
    ...initialAsset,
  });
  // const { setAssets:setGlobalAssets } = useAsset();
  const [tmpData, setTmpData] = useState<IAssetData>(initialAsset);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {
    availableCompanies,
    activeCompany,
    setAvailableCompaniesRaw,
    setCurrentActiveCompany,
  } = useCompany();
  const [localAssets, setLocalAssets] = useState<any[]>([]);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [selectedCompId, setSelectedCompId] = useState<string>();
  const { loginData } = useAuth();
  const { setDataFilter, dataFilter } = useFilter();

  const {
    query,
    isLoading,
    data,
    pagination,
    setSearch,
    changePage,
    fetchList,
  } = useFetchList<IAssetData, IQueryAsset>({
    endpoint: '/master/asset',
    initialQuery: {
      page: 1,
      max: INITIAL_QUERY.max,
    },
  });

  useEffect(() => {
    if (loginData && loginData.company && loginData.company.available) {
      setAvailableCompaniesRaw(loginData.company.available);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    if (isEditAssetModalOpen === false) {
      setTmpData({ ...initialAsset });
      form.resetFields();
      setIsEdit(false);
      setImageData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditAssetModalOpen]);

  const handleMenuClick = (key: any, assetData: IAssetData) => {
    setAssetDetail(assetData);

    if (key === 'detail') {
      setIsOpenAssetDetail(true);
    } else if (key === 'edit') {
      setIsEdit(true);
      setTmpData(assetData);
      setIsEditAssetModalOpen(true);
      form.setFieldsValue({
        ...assetData,
        massInstallationDate: moment(assetData.massInstallationDate),
      });
    } else if (key === 'delete') {
      setTmpData(assetData);
      confirmDelete(assetData.massId, assetData.massName);
    }
  };

  const handleCreateAsset = async (values: IAssetData) => {
    try {
      const formData = {
        massName: values?.massName,
        massType: values?.massType,
        massGsmNo: values?.massGsmNo,
        massImei: values?.massImei,
        massInstallationDate: values?.massInstallationDate
          ? moment(values?.massInstallationDate).format('YYYY-MM-DD HH:mm:ss')
          : undefined,
        massPicContact: values?.massPicContact,
        massPicName: values?.massPicName,
        massSateliteId: values?.massSateliteId,
        massSatDeviceId: values?.massSatDeviceId,
        massSateliteOperator: values?.massSateliteOperator,
        massSystemType: values?.massSystemType,
        massTelcoOperator: values?.massTelcoOperator,
        massCompId: selectedCompId,
        massDataId: 123,
      };

      const response = await AssetSvc.addAsset(formData);

      if (response.data && response.data.id && imageData) {
        let imageFormData = new FormData();
        imageFormData.append('image', imageData);

        await httpRequest.post(
          '/master/asset/upload/' + response.data.id,
          imageFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      try {
        await fetch('/email-new-asset', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({...formData, companyName: activeCompany?.compName}),
        });

        await fetch('/new-asset-spreadsheet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({...formData, companyName: activeCompany?.compName}),
        });
      } catch (error) {
        console.error('send new asset email error:', error);
      }

      message.success('Success add data ' + formData.massName);
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const handleEditAsset = async (values: IAssetData) => {
    try {
      let massInstallationDate;

      if (values?.massInstallationDate) {
        massInstallationDate = moment(values?.massInstallationDate).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      } else if (tmpData?.massInstallationDate) {
        massInstallationDate = moment(tmpData?.massInstallationDate).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }

      const formData = {
        massCompId: tmpData?.massCompId,
        massCreatedTime: tmpData?.massCreatedTime,
        massCreatedUserId: tmpData?.massCreatedUserId,
        massId: tmpData?.massId,
        massName: values?.massName,
        massType: values?.massType,
        massGsmNo: values?.massGsmNo || tmpData?.massGsmNo,
        massImei: values?.massImei || tmpData?.massImei,
        massInstallationDate,
        massPicContact: values?.massPicContact || tmpData?.massPicContact,
        massPicName: values?.massPicName || tmpData?.massPicName,
        massSateliteId: values?.massSateliteId || tmpData?.massSateliteId,
        massSatDeviceId: values?.massSatDeviceId || tmpData?.massSatDeviceId,
        massSateliteOperator:
          values?.massSateliteOperator || tmpData?.massSateliteOperator,
        massSystemType: values?.massSystemType || tmpData?.massSystemType,
        massTelcoOperator:
          values?.massTelcoOperator || tmpData?.massTelcoOperator,
        massUpdatedTime: tmpData?.massUpdatedTime,
        massUpdatedUserId: tmpData?.massUpdatedUserId,
        massDataId: Number(tmpData?.massId),
      };

      const res = await AssetSvc.updateAsset(formData.massId, formData);

      if (imageData && (res.status === 200 || res.status === 201)) {
        let imageFormData = new FormData();
        imageFormData.append('image', imageData);

        await httpRequest.post(
          '/master/asset/upload/' + tmpData.massId,
          imageFormData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      message.success('Success update data ' + formData.massName);
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const handleDeleteAsset = async (massId: string, massName: string) => {
    try {
      await AssetSvc.deleteAsset(massId);
      message.success(`Success delete ${massName} data`);
      setTmpData(initialAsset);
      handleCancelForm();
      await fetchList();
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);

  async function handleSubmitForm() {
    setIsLoadingAction(true);
    try {
      const values = await form.validateFields();
      if (values) {
        if (isEdit) {
          await handleEditAsset(values);
        } else {
          await handleCreateAsset(values);
        }
        handleCancelForm();
        fetchList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  function handleCancelForm() {
    setIsEditAssetModalOpen(false);
    setIsEdit(false);
  }

  const confirmDelete = (massId: string, massName: string) => {
    Modal.confirm({
      title: 'Delete Asset',
      content: `Are you sure want to delete "${massName}"?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDeleteAsset(massId, massName);
      },
    });
  };

  useEffect(() => {
    const setData = async () =>{
      if (data.length > 0) {
        const willBeSet = [];

        for (let asset of data) {
          const devices = await DeviceSvc.getDevicesByAssetId({ findField: 'massId', findValue: asset.massId });
          willBeSet.push({ ...asset, massDevice : devices.data.data });
        }

        setLocalAssets(willBeSet);
      }
    }
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (activeCompany && activeCompany.compId) {
      setSelectedCompId(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  const onChangeCompany = async (compId: string) => {
    try {
      setLoadingCompany(true);
      await AuthSvc.setCompany(compId);
      const assetsResponse = await AssetSvc.getAssets();

      if (assetsResponse.data && assetsResponse.data.data) {
        setLocalAssets(assetsResponse.data.data);
        setSelectedCompId(compId);

        await setCurrentActiveCompany({
          compId,
          setCompany: true,
        });

        setDataFilter((current) => ({
          ...current,
          compId,
          massId: assetsResponse.data.data[0].massId,
        }));
      }
    } catch (error) {
      console.error('failed get company', error);
    } finally {
      setLoadingCompany(false);
    }
  };

  return (
    <React.Fragment>
      <FilterContainer>
        <CustomForm label='Search'>
          <Input
            placeholder='Search by asset name'
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) =>
              setSearch({
                findField: 'massName',
                findValue: e.target.value,
              })
            }
          />
        </CustomForm>
        <CustomForm label='Company'>
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').toString().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toString()
                .toLowerCase()
                .localeCompare(
                  (optionB?.label.toLowerCase() ?? '').toLowerCase()
                )
            }
            loading={loadingCompany}
            value={selectedCompId}
            onChange={onChangeCompany}
            options={
              availableCompanies
                ? availableCompanies?.map((item) => ({
                    value: item.compId,
                    label: item.compName,
                  }))
                : []
            }
          />
        </CustomForm>
        <div />
        {handlePermission(['master_asset'], 'create') && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={() => {
              setIsEditAssetModalOpen(true);
              setPreviewImage(undefined);
            }}
          >
            Add Asset
          </Button>
        )}
      </FilterContainer>

      {isLoading ? (
        <ContainerCenter>
          <Spin />
        </ContainerCenter>
      ) : (
        <Content>
          <ContentWrapper>
            {localAssets.map((e: IAssetData, idx: number) => {
              return (
                <CardAssetItem
                  key={idx}
                  data={e}
                  onClick={() => {
                    setAssetDetail(e);
                    setIsOpenAssetDetail(true);
                  }}
                  onChooseMenu={(key) => handleMenuClick(key, e)}
                />
              );
            })}
          </ContentWrapper>
        </Content>
      )}

      <Pagination
        style={{ padding: '0 24px 24px' }}
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => changePage(page, perPage)}
      />

      {isOpenAssetDetail && (
        <DrawerDetail
          initialTitle='Asset Detail'
          initialState='device'
          open={isOpenAssetDetail}
          onClose={() => {
            setIsOpenAssetDetail(false);
            setAssetDetail({ ...initialAsset });
          }}
          detailAsset={assetDetail}
        />
      )}

      <Modal
        width={'45%'}
        title={isEdit ? 'Edit Asset' : 'Add Asset'}
        open={isEditAssetModalOpen}
        destroyOnClose
        confirmLoading={isLoadingAction}
        onCancel={handleCancelForm}
        className='custom-modal'
        footer={[
          <Button
            style={{ background: '#FFFFFF', color: '#000000' }}
            onClick={handleCancelForm}
          >
            Cancel
          </Button>,
          <Button
            loading={isLoadingAction}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={handleSubmitForm}
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>,
        ]}
      >
        <Form layout='vertical' form={form} onFinish={handleSubmitForm}>
          <Typography.Paragraph>Asset Image</Typography.Paragraph>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}
          >
            {previewImage !== undefined ? (
              <img
                alt='preview_image'
                src={previewImage}
                style={{ width: '45%', objectFit: 'cover' }}
              />
            ) : tmpData.massImage ? (
              <img
                alt='preview_image'
                src={`${baseUrl}/${tmpData.massImage}`}
                style={{ width: '45%', objectFit: 'cover' }}
              />
            ) : (
              <img
                alt='preview_image'
                src='/images/empty-image.png'
                style={{ width: '45%', objectFit: 'cover' }}
              />
            )}

            <div style={{ marginLeft: 24 }}>
              <label
                style={{
                  marginBottom: 12,
                  display: 'block',
                }}
              >
                <br />
                <input
                  type='file'
                  name='massImage'
                  accept='.jpg,.png,.jpeg'
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      setPreviewImage(undefined);
                      return;
                    }

                    // 2000000
                    if (e.target.files[0].size > 2000000) {
                      message.warn('Maximum fize size 2 Mb');
                      return;
                    }

                    const objectUrl = URL.createObjectURL(e.target.files[0]);
                    setImageData(e.target.files[0]);
                    setPreviewImage(objectUrl);
                  }}
                />
              </label>
              <Typography.Paragraph style={{ fontSize: 12, opacity: 0.6 }}>
                Max file size 2 MB. Extention accepted are jpg, png and jpeg
              </Typography.Paragraph>
            </div>
          </div>

          <GroupFormItem child={2}>
            <Form.Item
              label='Asset Name'
              name='massName'
              rules={[{ required: true }]}
            >
              <Input name='massName' placeholder='Input asset name' />
            </Form.Item>

            <Form.Item
              label='Type'
              name='massType'
              rules={[{ required: true }]}
            >
              <Select placeholder='Select type'>
                <Select.Option value='0'>Kapal</Select.Option>
                <Select.Option value='1'>Mobil</Select.Option>
              </Select>
            </Form.Item>
          </GroupFormItem>

          <Form.Item label='GSM No.' name='massGsmNo'>
            <Input name='massGsmNo' placeholder='Input GSM No.' />
          </Form.Item>

          <Form.Item label='Imei' name='massImei'>
            <Input name='massImei' placeholder='Input Imei' />
          </Form.Item>

          <Form.Item label='Installation Date' name='massInstallationDate'>
            <DatePicker name='massInstallationDate' style={{ width: '100%' }} />
          </Form.Item>

          <GroupFormItem child={2}>
            <Form.Item label='PIC Name' name='massPicName'>
              <Input name='massPicName' placeholder='Input PIC Name' />
            </Form.Item>
            <Form.Item label='PIC Phone Number' name='massPicContact'>
              <Input
                name='massPicContact'
                placeholder='Input PIC Phone Number'
              />
            </Form.Item>
          </GroupFormItem>

          <GroupFormItem child={3}>
            <Form.Item label='Satellite ID' name='massSateliteId'>
              <Input name='massSateliteId' placeholder='Input Satellite ID' />
            </Form.Item>
            <Form.Item label='Satellite Device ID' name='massSatDeviceId'>
              <Input
                name='massSatDeviceId'
                placeholder='Input Satellite Device ID'
              />
            </Form.Item>
            <Form.Item label='Satellite Operator' name='massSateliteOperator'>
              <Input
                name='massSateliteOperator'
                placeholder='Input Satellite Operator'
              />
            </Form.Item>
          </GroupFormItem>

          <Form.Item
            label='Type'
            name='massSystemType'
            rules={[{ required: true }]}
          >
            <Select placeholder='System type'>
              <Select.Option value='FMS'>FMS</Select.Option>
              <Select.Option value='EMS'>EMS</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label='Telco Operator' name='massTelcoOperator'>
            <Input
              name='massTelcoOperator'
              placeholder='Input Telco Operator'
            />
          </Form.Item>

          {/* <Form.Item
            style={{ display: "inline-block", width: "49%" }}
            label="Status"
          >
            <Select placeholder="Select Status">
              <Select.Option value="ACTIVE">Active</Select.Option>
              <Select.Option value="INACTIVE">Inactive</Select.Option>
            </Select>
          </Form.Item> */}
          {/* <h1>Device List</h1> */}
          {/* <Table
            size="middle"
            columns={[
              {
                render: () => <Checkbox />,
              },
              {
                title: "Device Name",
                dataIndex: "deviceName",
                sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
              },
              {
                title: "Device Type",
                dataIndex: "deviceType",
                sorter: (a, b) => a.deviceType.localeCompare(b.deviceType),
              },
              {
                title: "Device ID",
                dataIndex: "deviceId",
                sorter: (a, b) => a.deviceId.localeCompare(b.deviceId),
              },
            ]}
            dataSource={[
              {
                deviceName: "test",
                deviceType: "test",
                deviceId: "test",
              },
              {
                deviceName: "test",
                deviceType: "test",
                deviceId: "test",
              },
            ]}
          /> */}
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Asset;
// const FilterContainer = styled.div`
//   display: grid;
//   gap: 15px;
//   align-items: flex-end;
//   grid-template-columns: 20% 300px 150px 150px 1fr auto;
//   padding: 15px 20px;
// `;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 300px 300px 1fr auto;
  padding: 15px 20px;
`;

const Content = styled.div`
  padding: 20px 20px;
  /* height: 100%; */
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
`;

const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
