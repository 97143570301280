import { Image } from "antd";
import React from "react";
import styled from "styled-components";

type AppLogoProps = {
  collapsed: boolean;
  title?: string;
};

const AppLogo: React.FC<AppLogoProps> = ({ collapsed, title }) => {
  return (
    <LogoEtanaContainer collapsed={collapsed}>
      {collapsed ? (
        <AppTitle>
          {/* {process.env.REACT_APP_WEBSITE_NAME} */}
          <Image
            preview={false}
            width={50}
            src={"/images/logo-ramus.svg"}
            style={{
              objectFit: "cover",
            }}
          />
        </AppTitle>
      ) : (
        <AppTitle>
          {/* {process.env.REACT_APP_WEBSITE_NAME} */}
          <Image
            preview={false}
            width={60}
            src={"/images/logo-ramus.svg"}
            style={{
              objectFit: "cover",
            }}
          />
          {title && <AppTitleText>{title}</AppTitleText>}
        </AppTitle>
      )}
    </LogoEtanaContainer>
  );
};

type LogoEtanaContainerProps = {
  collapsed: boolean;
};

const LogoEtanaContainer = styled.div<LogoEtanaContainerProps>`
  padding: ${({ collapsed }) => (collapsed ? "0" : "0 24px")};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
  background-color: #272a35;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const AppTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

const AppTitleText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.fontSize.body1};
  line-height: 26px;
`;

// const AppAvatar = styled(Avatar)`
//   background-color: ${({ theme }) => theme.colors.primary};
//   color: ${({ theme }) => theme.colors.white};
//   font-weight: ${({ theme }) => theme.fontWeight.semiBold};
// `

export default AppLogo;
