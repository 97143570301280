import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Pagination, Select } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CustomForm from '../../components/CustomForm';
import GroupSvc from '../../services/GroupSvc';
import CITIES from '../../types/city';
import {
  IGroupCompanyData,
  initialGroupCompany,
} from '../../types/groupCompany.type';
import { ContainerContent } from '../layout/AppLayout';
import { DataTableEmpty } from '../company';
import useAuth from '../../hooks/useAuth';
import { INITIAL_QUERY } from '../../helpers/pagination';
import useFetchList from '../../hooks/useFetchList';
import DrawerDetail from '../../components/DrawerDetail/DrawerDetail';
import styled from 'styled-components';

const Group = () => {
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] =
    useState<boolean>(false);
  const [isOpenGroupDetail, setIsOpenGroupDetail] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [groupDetail, setGroupDetail] = useState<IGroupCompanyData>({
    ...initialGroupCompany,
  });
  const [editId, setEditId] = useState<string>('');
  const { handlePermission } = useAuth();
  const [form] = Form.useForm();

  const {
    query,
    isLoading,
    data,
    pagination,
    setSearch,
    changePage,
    fetchList,
    setQuery,
  } = useFetchList<IGroupCompanyData>({
    endpoint: '/master/group_company',
    initialQuery: {
      page: 1,
      max: INITIAL_QUERY.max,
    },
  });

  useEffect(() => {
    if (isEditGroupModalOpen === false) {
      form.resetFields();
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditGroupModalOpen]);

  const handleMenuClick = (key: any, groupCompanyData: IGroupCompanyData) => {
    setGroupDetail(groupCompanyData);

    if (key === 'detail') {
      setIsOpenGroupDetail(true);
    } else if (key === 'edit') {
      setIsEdit(true);
      setEditId(groupCompanyData.grcpId);
      setIsEditGroupModalOpen(true);
      form.setFieldsValue(groupCompanyData);
    } else if (key === 'delete') {
      confirmDelete(groupCompanyData.grcpId, groupCompanyData.grcpName);
    }
  };

  const handleDelete = async (grcpId: string, grcpName: string) => {
    try {
      await GroupSvc.delete(grcpId);
      message.success('Success delete ' + grcpName + ' group.');
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleEditGroup = async (values: IGroupCompanyData) => {
    try {
      const formData = {
        grcpAddress: values?.grcpAddress,
        grcpCity: values?.grcpCity,
        grcpFax: values?.grcpFax,
        grcpName: values?.grcpName,
        grcpNickName: values?.grcpNickName,
        grcpTelp: values?.grcpTelp,
      };
      await GroupSvc.update(editId, formData);
      message.success('Success update data ' + formData.grcpName);
    } catch (error) {
      message.error('Error: ' + error);
    }
    setEditId('');
  };

  const handleCreateGroup = async (value: IGroupCompanyData) => {
    try {
      const formData = {
        grcpName: value?.grcpName,
        grcpAddress: value?.grcpAddress,
        grcpCity: value?.grcpCity,
        grcpFax: value?.grcpFax,
        grcpNickName: value?.grcpNickName,
        grcpTelp: value?.grcpTelp,
      };
      await GroupSvc.create(formData);
      message.success('Success add data ' + formData.grcpName);
    } catch (error) {
      message.error('Error: ' + error);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Group Name',
      dataIndex: 'grcpNickName',
      key: 'grcpNickName',
    },
    {
      title: 'Initial',
      dataIndex: 'grcpName',
      key: 'grcpName',
      render: (grcpName: string, record: IGroupCompanyData) => (
        <div
          style={
            handlePermission(['master_group_company'], 'read')
              ? {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#0957C2',
                }
              : {}
          }
          onClick={() => {
            if (handlePermission(['master_group_company'], 'read')) {
              setGroupDetail(record);
              setIsOpenGroupDetail(true);
            }
          }}
        >
          {grcpName}
        </div>
      ),
    },
    {
      title: 'Telp.',
      dataIndex: 'grcpTelp',
      key: 'grcpTelp',
      render: (grcpTelp: string) =>
        grcpTelp || <DataTableEmpty>Not set</DataTableEmpty>,
    },
    {
      title: 'City',
      dataIndex: 'grcpCity',
      key: 'grcpCity',
      render: (grcpCity: string) =>
        grcpCity || <DataTableEmpty>Not set</DataTableEmpty>,
    },
    {
      title: 'Updated at',
      dataIndex: 'grcpUpdatedTime',
      key: 'grcpUpdatedTime',
      render: (date: Date) => <span>{moment(date).format('DD MMM YYYY')}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record: IGroupCompanyData) => {
        return (
          <GroupActionButton>
            {handlePermission(['master_group_company'], 'update') && (
              <Button
                onClick={() => handleMenuClick('edit', record)}
                size='small'
                value='edit'
              >
                Edit
              </Button>
            )}
            {handlePermission(['master_group_company'], 'delete') && (
              <Button
                onClick={() => handleMenuClick('delete', record)}
                size='small'
                danger
                value='delete'
              >
                Delete
              </Button>
            )}
          </GroupActionButton>
        );
      },
    },
  ];

  const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);

  async function handleSubmitForm() {
    setIsLoadingAction(true);
    try {
      const values = await form.validateFields();
      if (values) {
        if (editId) {
          await handleEditGroup(values);
        } else {
          await handleCreateGroup(values);
        }
        handleCancelForm();
        fetchList();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  function handleCancelForm() {
    setIsEditGroupModalOpen(false);
  }

  const confirmDelete = (grcpId: string, grcpName: string) => {
    Modal.confirm({
      title: 'Delete Group Company',
      content: `Are you sure want to delete "${grcpName}"?`,
      okText: 'Yes, Sure',
      cancelText: 'Cancel',
      onOk: async () => {
        await handleDelete(grcpId, grcpName);
      },
      afterClose: async () => {
        setQuery((currQuery) => ({
          ...currQuery,
          page: 1,
        }));
      },
    });
  };

  return (
    <ContainerContent>
      <FilterContainer>
        <CustomForm label='Search'>
          <Input
            style={{ width: 300 }}
            placeholder='Search by group name'
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) =>
              setSearch({
                findField: 'grcpName',
                findValue: e.target.value,
              })
            }
          />
        </CustomForm>
        <div />
        {handlePermission(['master_group_company'], 'create') && (
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={() => {
              setIsEditGroupModalOpen(true);
            }}
          >
            Add Group
          </Button>
        )}
      </FilterContainer>

      <Table
        loading={isLoading}
        size='middle'
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <Pagination
        total={pagination.totalData}
        current={pagination.page}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={query.max}
        defaultCurrent={1}
        onChange={(page, perPage) => changePage(page, perPage)}
      />

      {isOpenGroupDetail && (
        <DrawerDetail
          initialTitle='Group Company Detail'
          initialState='company'
          open={isOpenGroupDetail}
          onClose={() => {
            setIsOpenGroupDetail(false);
            setGroupDetail({ ...initialGroupCompany });
          }}
          detailGroup={groupDetail}
        />
      )}

      <Modal
        width={'45%'}
        title={isEdit ? 'Edit Group' : 'Add Group'}
        open={isEditGroupModalOpen}
        destroyOnClose
        onCancel={handleCancelForm}
        className='custom-modal'
        footer={[
          <Button
            style={{ background: '#FFFFFF', color: '#000000' }}
            onClick={handleCancelForm}
          >
            Cancel
          </Button>,
          <Button
            loading={isLoadingAction}
            disabled={!handlePermission(['master_group_company'], 'create')}
            style={{ background: '#0957C2', color: '#FFFFFF' }}
            onClick={handleSubmitForm}
            key='submit'
            htmlType='submit'
          >
            {isEdit ? 'Update' : 'Create'}
          </Button>,
        ]}
      >
        <Form layout='vertical' form={form} onFinish={handleSubmitForm}>
          <GroupFormItem child={2}>
            <Form.Item
              label='Group Name'
              name='grcpName'
              rules={[{ required: true }]}
            >
              <Input placeholder='Enter group name' />
            </Form.Item>

            <Form.Item
              name='grcpNickName'
              label='Group Initial'
              rules={[{ required: true }]}
            >
              <Input placeholder='Enter group initial' />
            </Form.Item>
          </GroupFormItem>

          <Form.Item>
            <Form.Item
              style={{ width: '100%' }}
              name='grcpCity'
              label='City'
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label.toLowerCase() ?? '').toLowerCase()
                    )
                }
                placeholder='Select City'
                options={CITIES.map((item) => ({
                  value: item.cityName,
                  label: item.cityName,
                }))}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label='Address'
            name='grcpAddress'
            rules={[{ required: true }]}
          >
            <Input placeholder='Enter address' />
          </Form.Item>

          <GroupFormItem child={2}>
            <Form.Item label='Fax' name='grcpFax'>
              <Input placeholder='Enter Fax' />
            </Form.Item>

            <Form.Item
              name='grcpTelp'
              label='Telephone'
              rules={[{ required: true }]}
            >
              <Input placeholder='Enter Telephone' />
            </Form.Item>
          </GroupFormItem>
        </Form>
      </Modal>
    </ContainerContent>
  );
};

export const GroupActionButton = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  width: 170px;
`;

export const GroupFormItem = styled.div<{ child: number }>`
  display: grid;
  gap: 10px;
  grid-template-columns: ${(props) => `repeat(${props.child}, 1fr)`};
`;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 300px 1fr auto;
  padding: 0px 0px 16px;
`;

export default Group;
