import { Collapse, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useCompany from '../hooks/useCompany';
import CompanyImage from './CompanyImage';
import useAsset from '../hooks/useAsset';
import { ICompAuthStatus } from '../types/company.type';
import CompanySvc from '../services/CompanySvc';

interface Props {
  open: boolean;
  onCloseModal: () => void;
  onCloseModalSubmit: () => void;
}

const { Panel } = Collapse;

const ModalChangeCompany: React.FC<Props> = (props) => {
  const { fetchAssets, fetchAssetsWithLastData, isLoadingAssetSummary } =
    useAsset();
  const {
    activeCompany,
    isLoadingCompanies,
    isLoadingCompanyData,
    availableCompaniesByGroup,
    setCurrentActiveCompany,
    availableCompaniesRaw,
    setAvailableCompaniesRaw,
  } = useCompany();

  const [tmpActiveCompany, setTmpActiveCompany] = useState<ICompAuthStatus>();

  const handleSetTmpCompany = (company: ICompAuthStatus) => {
    setTmpActiveCompany(company);
  };

  const handleChangeCompany = async () => {
    if (tmpActiveCompany) {
      await setCurrentActiveCompany({
        compId: tmpActiveCompany.compId,
        compGrcpId: tmpActiveCompany.compGrcpId,
        setCompany: true,
        getCompany: true,
      });
      await fetchAssets();
      // await fetchAssetsWithLastData();
    }
    props.onCloseModalSubmit();
  };

  const fetchCompany = async () => {
    try {
      const results = await CompanySvc.getCompanies({});
      let newRawCompany: any = [];

      if (availableCompaniesRaw) {
        newRawCompany = Object.values(availableCompaniesRaw).map((item) => {
          const found = results.data.data.find(
            (a: any) => a.compId === item.compId
          );
          if (found) {
            return {
              ...item,
              compImage: found.compImage,
            };
          }
          return item;
        });
      }

      if (newRawCompany.length > 0) {
        setAvailableCompaniesRaw(newRawCompany);
      }
    } catch (error) {
      console.error('failed fetch company', error);
    }
  };

  useEffect(() => {
    if (props.open) {
      fetchCompany();
    }
  }, [props.open]);

  useEffect(() => {
    if (activeCompany) {
      setTmpActiveCompany(activeCompany);
    }
  }, [activeCompany]);

  return (
    <Modal
      width={700}
      open={props.open}
      okText='Confirm Selection'
      onCancel={props.onCloseModal}
      onOk={handleChangeCompany}
      title={
        <TitleWrapper>
          <Title>Change Company</Title>
          <SubTitle>Select Group and Company that you want to trace</SubTitle>
        </TitleWrapper>
      }
      confirmLoading={isLoadingCompanyData || isLoadingAssetSummary}
    >
      {isLoadingCompanies ? (
        <ContainerLoading>
          <Spin />
          <div>Loading data company</div>
        </ContainerLoading>
      ) : availableCompaniesByGroup && availableCompaniesByGroup.length > 0 ? (
        <Collapse accordion defaultActiveKey={[String(0)]} bordered={false}>
          {availableCompaniesByGroup.map((item, idx) => (
            <Panel
              header={`${item.group.grcpName} (${item.companies.length})`}
              key={String(idx)}
            >
              <CompanyContainer>
                {item.companies.map((company, idx) => (
                  <CompanyCard
                    active={
                      tmpActiveCompany
                        ? tmpActiveCompany.compId === company.compId
                        : false
                    }
                    key={idx}
                    onClick={() => handleSetTmpCompany(company)}
                  >
                    <CompanyImage compImage={company.compImage} />
                    <div>{company.compName}</div>
                  </CompanyCard>
                ))}
              </CompanyContainer>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <ContainerLoading>
          <div style={{ opacity: 0.5 }}>Data Empty</div>
        </ContainerLoading>
      )}
    </Modal>
  );
};

export default ModalChangeCompany;

interface ITitleWrapper {
  center?: boolean;
}

export const TitleWrapper = styled.div<ITitleWrapper>`
  background-color: #e8ecf3;
  padding: 15px 20px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const SubTitle = styled.div`
  font-size: 12px;
  color: #768499;
  font-weight: 400;
  margin-top: 5px;
`;

export const CompanyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

interface ICompanyCard {
  active: boolean;
}

export const CompanyCard = styled.div<ICompanyCard>`
  padding: 16px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  border: ${(props) =>
    props.active ? '1px solid #0957C2' : '1px solid #e8ecf3'};
  color: ${(props) => (props.active ? '#0957C2' : '#1e1e1e')};
  box-shadow: 0px 3px 2px 0px #0000000d;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 3px;
    border: 1px solid #c5cddb;
  }
`;

const ContainerLoading = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
