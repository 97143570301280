import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomForm from '../../components/CustomForm';
import {
  TeamOutlined,
  FlagOutlined,
  PhoneOutlined,
  PrinterOutlined,
  UserOutlined,
  PlusOutlined,
  SearchOutlined,
  HomeOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  Row,
  Select,
  Tabs,
  Input,
  Dropdown,
  Modal,
  Divider,
  Form,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import buildFilters from '../../helpers/requestBuilder';
import AuthSvc from '../../services/AuthSvc';
import CompanySvc from '../../services/CompanySvc';
import AssetSvc from '../../services/AssetSvc';
import DeviceSvc from '../../services/DeviceSvc';
import { AssetType } from '../../types/asset.type';
import useAuth from '../../hooks/useAuth';
import useCompany from '../../hooks/useCompany';
import useAsset from '../../hooks/useAsset';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import DetailData from '../../components/DrawerDetail/DetailData';
import {
  ICompAuthStatus,
  ICompanyData,
  initialCompanyType,
  initialCompAuthStatus,
} from '../../types/company.type';
import { IAssetData, initialAsset } from '../../types/asset.type';
import AssetIndex from '../asset/index';
import axios from 'axios';
import { MoreHorizontal } from 'react-feather';
import { generateFormRules } from '../../helpers/formRules';
import moment from 'moment';

interface ILocation {
  compId: string;
}

interface DataType {
  id: string;
  compId: string;
  massId: string;
  massName: string;
  evpaEmail: string;
  evpaName: string;
}

interface AxiosErrorWithData extends Error {
  response?: any;
}

const CompanyDetail = () => {
  const { compId } = useParams<keyof ILocation>() as ILocation;
  const [grcpId, setGrcpId] = useState<string>('');
  const [tokenQbit, setTokenQbit] = useState<string>('');
  const { state } = useLocation();
  const { compName } = state;
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const { handlePermission } = useAuth();
  const [typeInput, setTypeInput] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [compData, setCompData] = useState<ICompanyData>({
    ...initialCompanyType,
  });
  const [massData, setMassData] = useState<AssetType[]>();
  const [compType, setCompType] = useState<ICompAuthStatus>({
    ...initialCompAuthStatus,
  });
  const [recordReceiverId, setRecordReceiverId] = useState<string>('');
  const { assets, setAssets } = useAsset();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [assetDetail, setAssetDetail] = useState<IAssetData>({
    ...initialAsset,
  });
  const [isEditReceiverModalOpen, setIsEditReceiverModalOpen] =
    useState<boolean>(false);
  const [isDeleteReceiverModalOpen, setIsDeleteReceiverModalOpen] =
    useState<boolean>(false);
  const initialDataType: DataType = {
    id: '',
    compId: '',
    massId: '',
    massName: '',
    evpaEmail: '',
    evpaName: '',
  };
  const [tmpData, setTmpData] = useState<DataType>({ ...initialDataType });
  const [dataReceiver, setDataReceiver] = useState<any[]>([]);
  const [isOpenAssetDetail, setIsOpenAssetDetail] = useState<boolean>(false);
  const [filter, setFilter] = useState({
    current: 1,
    pageSize: 25,
  });
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const handleFetchData = async () => {
    setIsLoadingData(true);
    try {
      const dataCompany = await CompanySvc.getCompanyById(compId);

      setCompData(dataCompany.data.data[0]);
      setGrcpId(dataCompany?.data?.data[0].compGrcp.grcpId);

      const resAssets = await AssetSvc.getAssets();
      setMassData(resAssets.data.data);
    } catch (error) {
      console.error('===> err handleSetCompany: ', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const baseUrlOnly = process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL.slice(0, -3)
    : null;

  const getToken = async () => {
    try {
      const data = {
        email: 'development.qbit@gmail.com',
        password: 'Secret*123',
      };
      const config = {
        timeout: 90000,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
      };
      if (baseUrlOnly) {
        const respToken = await axios.post(
          baseUrlOnly + 'service-api/auth/signin',
          data,
          config
        );
        setTokenQbit(respToken.data?.payload?.token);
      }
    } catch (error: any) {
      console.error('failed qbit login', error);
    }
  };

  const getReceiver = async () => {
    if (tokenQbit == '') {
      getToken();
    }
    const config = {
      headers: {
        Authorization: 'Bearer ' + tokenQbit,
        'Content-Type': 'application/json',
      },
      params: {
        compId: compId,
        grcpId: grcpId,
      },
    };
    const resp = await axios.get('http://localhost:3000/receiver', config);
    setDataReceiver(resp.data?.payload?.results);
  };

  const handleMenuClick = (item: any, record: DataType) => {
    setTmpData(initialDataType);
    setRecordReceiverId(record.id);
    if (item.key == 'edit') {
      setIsEdit(true);
      setTmpData(record);
      setIsEditReceiverModalOpen(true);
    }

    if (item.key == 'delete') {
      setIsDeleteReceiverModalOpen(true);
    }
  };

  const convertDate = (date: any) => {
    return moment(date).format('DD MMM YYYY, HH:mm');
  };

  const dataFetchedRef = useRef(false);

  const mapDataCompany = useMemo(() => {
    if (compData) {
      return {
        title: {
          label: 'Company Name',
          value: `${compData.compName}`,
        },
        data: [
          {
            icon: <PrinterOutlined color='#768499' size={16} />,
            label: 'Phone Number',
            value: compData.compPhone,
          },
          {
            icon: <PhoneOutlined color='#768499' size={16} />,
            label: 'PIC Name',
            value: compData.compPIC,
          },
          {
            icon: <HomeOutlined color='#768499' size={16} />,
            label: 'Address',
            value: compData.compAddress,
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Created At',
            value: convertDate(compData.compCreatedTime),
          },
          {
            icon: <CalendarOutlined color='#768499' size={16} />,
            label: 'Updated At',
            value: convertDate(compData.compUpdatedTime),
          },
          {
            icon: <UserOutlined color='#768499' size={16} />,
            label: 'Created By',
            value: compData.compCreatedUserId,
          },
        ],
      };
    }
  }, [compData]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'evpaName',
      render: (evpaName: string) =>
        evpaName || <DataTableEmpty>Not set</DataTableEmpty>,
    },
    {
      title: 'Email',
      dataIndex: 'evpaEmail',
      render: (evpaEmail: string) =>
        evpaEmail || <DataTableEmpty>Not set</DataTableEmpty>,
    },
    {
      title: 'Asset',
      dataIndex: 'massName',
      render: (massName: string) =>
        massName || <DataTableEmpty>Not set</DataTableEmpty>,
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record: DataType) => {
        const items: any = [
          {
            key: 'edit',
            label: 'Edit',
            style: { minWidth: 120 },
            show: handlePermission(['master_company'], 'update'),
          },
          {
            key: 'delete',
            label: 'Delete',
            danger: true,
            show: handlePermission(['master_company'], 'delete'),
            style: { minWidth: 120 },
          },
        ];
        return (
          <Dropdown
            menu={{
              items: items.filter((item: any) => item.show),
              onClick: (e) => handleMenuClick(e, record),
            }}
            placement='bottomRight'
          >
            <MoreHorizontal color='#768499' size={20} />
          </Dropdown>
        );
      },
    },
  ];

  const handleSubmit = async (values: any) => {
    setTmpData(initialDataType);
    saveReceiver(values);
  };

  const saveReceiver = async (values: any) => {
    if (tokenQbit === '') {
      getToken();
    }

    const dataAsset = Object.values(assets).find(
      (asset) => asset.massId === values.massId
    );
    const config = {
      headers: {
        Authorization: 'Bearer ' + tokenQbit,
        'Content-Type': 'application/json',
      },
    };

    const devices = await DeviceSvc.getDevicesByAssetId({
      findField: 'devcMassId',
      findValue: values.massId,
    });

    const gateway: any = Object.values(devices.data.data).find(
      (device: any) => device.devcType === 'gateway'
    );

    const receiverData = {
      massId: values.massId,
      massName: dataAsset?.massName,
      evpaEmail: values.evpaEmail,
      evpaName: values.evpaName,
      compId: compId,
      compName: compName,
      grcpId: grcpId,
      isInternal: false,
      gatewayId: gateway.devcUniqueId,
    };

    try {
      let resp;
      if (isEdit && recordReceiverId) {
        resp = await axios.patch(
          `http://localhost:3000/receiver/${recordReceiverId}`,
          receiverData,
          config
        );
        message.success(
          resp.request?.status === 200
            ? 'Data edited successfully.'
            : `Error edit: ${resp}`
        );
      } else {
        resp = await axios.post(
          'http://localhost:3000/receiver',
          receiverData,
          config
        );
        message.success(
          resp.request?.status === 201
            ? 'Data created successfully.'
            : `Error create: ${resp}`
        );
      }
    } catch (error) {
      const axiosError = error as AxiosErrorWithData;
      if (axiosError.response.data.message) {
        message.warn(`Error save: ${axiosError.response.data.message}`);
      }
    }

    setIsEditReceiverModalOpen(false);
    form.resetFields();
    getReceiver();
  };

  const handleDelete = async () => {
    if (tokenQbit == '') {
      getToken();
    }
    const config = {
      headers: {
        Authorization: 'Bearer ' + tokenQbit,
        'Content-Type': 'application/json',
      },
    };

    try {
      const resp = await axios.delete(
        `http://localhost:3000/receiver/${recordReceiverId}`,
        config
      );
      if (resp.request?.status == 200) {
        message.success('Data deleted successfully.');
      }
    } catch (error) {
      const axiosError = error as AxiosErrorWithData;
      if (axiosError.response.data.message) {
        message.warn(`Error save: ${axiosError.response.data.message}`);
      }
    }
    getReceiver();
    setIsDeleteReceiverModalOpen(false);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const bcDetails = [
      {
        field: 'compId',
        value: compId,
        label: compName,
      },
    ];
    setBreadcrumbDetails(bcDetails);
    handleFetchData();
    getToken();
  }, []);

  useEffect(() => {
    if (tokenQbit !== '') {
      getReceiver();
    }
  }, [tokenQbit]);

  useEffect(() => {
    form.setFieldsValue(tmpData);
  }, [tmpData]);

  useEffect(() => {
    if (isEditReceiverModalOpen === false) {
      setIsEdit(false);
    }
  }, [isEditReceiverModalOpen]);

  return (
    <React.Fragment>
      <Row justify='end'>
        {handlePermission(['master_asset', 'master_device'], 'create') && (
          <Button
            style={{ background: '#FFFFFF', color: '#000000', margin: '5px' }}
            onClick={() => {}}
          >
            Edit Company
          </Button>
        )}

        {handlePermission(['master_asset', 'master_device'], 'create') && (
          <Button
            style={{ background: '#FE4545', color: '#FFFFFF', margin: '5px' }}
            onClick={() => {}}
          >
            Delete Company
          </Button>
        )}
      </Row>
      <Row>
        <Col span={8}>
          {mapDataCompany && (
            <DetailData
              data={mapDataCompany.data}
              title={mapDataCompany.title}
            />
          )}
        </Col>
        <Col span={16}>
          <Tabs>
            <Tabs.TabPane tab='Asset List' key='item-1'>
              <AssetIndex />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Email Receiver' key='item-2'>
              <FilterContainer>
                <CustomForm label='Search'>
                  <Input
                    onChange={(e) => setSearchInput(e.target.value)}
                    style={{ width: 300 }}
                    placeholder='Search by name or email'
                    prefix={<SearchOutlined />}
                  />
                </CustomForm>
                <div />
                {handlePermission(['master_asset'], 'create') && (
                  <Button
                    icon={<PlusOutlined />}
                    style={{ background: '#0957C2', color: '#FFFFFF' }}
                    onClick={() => {
                      setIsEditReceiverModalOpen(true);
                    }}
                  >
                    Add Email
                  </Button>
                )}
              </FilterContainer>
              <Modal
                width={700}
                open={isEditReceiverModalOpen}
                onCancel={() => {
                  setIsEditReceiverModalOpen(false);
                }}
                afterClose={() => {
                  form.resetFields();
                  setTmpData(initialDataType);
                  setRecordReceiverId('');
                }}
                footer={[
                  <Button
                    style={{ background: '#FFFFFF', color: '#000000' }}
                    onClick={() => {
                      setIsEditReceiverModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>,
                  <Button
                    disabled={
                      !handlePermission(['master_company'], 'create') &&
                      !handlePermission(['master_company'], 'update')
                    }
                    style={{ background: '#0957C2', color: '#FFFFFF' }}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          handleSubmit(values);
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    Save
                  </Button>,
                ]}
              >
                <h2 style={{ width: '100%', fontWeight: 'bold' }}>
                  {' '}
                  {isEdit ? 'Edit Email' : 'Add Email'}
                </h2>
                <Divider />
                <Form
                  layout='vertical'
                  form={form}
                  onFinish={handleSubmit}
                  initialValues={tmpData}
                >
                  <Form.Item
                    label='Name'
                    name='evpaName'
                    rules={generateFormRules('Name', ['required'])}
                  >
                    <Input name='Name' />
                  </Form.Item>
                  <Form.Item
                    label='Email'
                    name='evpaEmail'
                    rules={generateFormRules('Email', ['required', 'email'])}
                  >
                    <Input name='Email' />
                  </Form.Item>
                  <Form.Item
                    label='Asset'
                    name='massId'
                    rules={generateFormRules('Asset', ['required'])}
                  >
                    <Select placeholder='Select Asset' value={tmpData?.massId}>
                      {Object.values(assets).map((asset, index) => {
                        return (
                          <Select.Option key={index} value={asset.massId}>
                            {asset.massName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Form>
              </Modal>
              <Modal
                title='Delete Confirmation'
                open={isDeleteReceiverModalOpen}
                onOk={handleDelete}
                onCancel={() => {
                  setIsDeleteReceiverModalOpen(false);
                  setRecordReceiverId('');
                }}
                okText='Yes, sure'
                cancelText='Cancel'
              >
                <div style={{ textAlign: 'center' }}>
                  <Typography.Paragraph>
                    Are you sure want to delete?
                  </Typography.Paragraph>
                </div>
              </Modal>
              <Table
                style={{
                  padding: '15px 20px',
                }}
                size='middle'
                columns={columns}
                dataSource={dataReceiver}
                pagination={{
                  pageSize: filter.pageSize,
                  showTotal(total, range) {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  },
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CompanyDetail;

const FilterContainer = styled.div`
  display: grid;
  gap: 15px;
  align-items: flex-end;
  grid-template-columns: 20% 150px 200px 1fr auto;
  padding: 15px 20px;
`;

const Content = styled.div`
  padding: 20px 20px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.grayLighten_Default};
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
`;

export const DataTableEmpty = styled.div`
  color: lightgray;
  font-style: italic;
`;
