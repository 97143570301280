import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useSearchParams } from 'react-router-dom';
import FilterDashboard from '../dashboard/components/FilterDashboard';
import useFilter from '../../hooks/useFilter';
import useAsset from '../../hooks/useAsset';
import SummarySvc from '../../services/SummarySvc';
import moment from 'moment';
import useCompany from '../../hooks/useCompany';
import { generateTimestampArray, toHHMMSS } from '../../helpers/dateUtil';
import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import DeviceSvc from '../../services/DeviceSvc';
import { getLabelFromKeyOperationMode } from '../../helpers/text';

const ReportOperationMode: React.FC = () => {
  interface OperationModeData {
    [key: string]: {
      value: number;
      runningSeconds: number;
      maxRpm: string;
    };
  }

  const [operationModeSummary, setOperationModeSummary] = useState<any>([]);
  const [operationModeSeries, setOperationModeSeries] = useState<any>();

  const [searchParams, setSearchParams] = useSearchParams();
  const { dataFilter } = useFilter();
  const { assets2, fetchAssets } = useAsset();
  const { fetchCompanyData, activeCompany } = useCompany();

  const windowHeight = window.innerHeight;
  const [vt, set_components] = useVT(
    () => ({ scroll: { y: windowHeight - 300, x: 700 } }),
    []
  );

  const dataFetchedRef = useRef(false);

  const currentAsset = useMemo(() => {
    return assets2.find((item) => item.massId === dataFilter.massId);
  }, [dataFilter.massId, assets2]);

  const handleSetURLParams = (data: any) => {
    setSearchParams(data);
  };

  const fetchOperationMode = async () => {
    if (!currentAsset?.isHaveOperationMode) return;
    const devc = currentAsset?.massDevice.find(
      (elm: any) => elm.devcType === 'rpm'
    );

    console.log('currentAsset', currentAsset);

    const responseSeries = await SummarySvc.getSeriesOperationMode({
      start: String(moment(dataFilter.range.startAt).valueOf() / 1000),
      end: String(moment(dataFilter.range.endAt).valueOf() / 1000),
      params: { devcId: devc?.devcId },
    });

    const responseSummary = await SummarySvc.getSummaryOperationMode({
      start: String(moment(dataFilter.range.startAt).valueOf() / 1000),
      end: String(moment(dataFilter.range.endAt).valueOf() / 1000),
      params: { devcId: devc?.devcId },
    });

    const dataTotal: any = responseSummary.data?.data?.total;

    const start = moment(dataFilter.range.startAt).unix();
    const end = moment(dataFilter.range.endAt).unix();
    const series = responseSeries?.data?.data?.data;
    let newCategories: any[] = generateTimestampArray(start, end, 60);

    const data = newCategories.map((timestamp) => {
      const currData = series[timestamp];
      return {
        id: timestamp,
        dateTime: moment(timestamp * 1000).format('DD MMM YYYY, HH:mm:ss'),
        stbOpsMode: currData?.STARBOARD?.mode,
        stbRPM: currData?.STARBOARD?.rpm,
        stbFuelCons: currData?.STARBOARD?.value,
        portOpsMode: currData?.PORT?.mode,
        portRPM: currData?.PORT?.rpm,
        portFuelCons: currData?.PORT?.value,
      };
    });

    if (devc?.devcId) {
      if (responseSeries?.data?.data) {
        setOperationModeSeries(data);
      }
      if (responseSummary?.data?.data) {
        setOperationModeSummary(dataTotal);
      }
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    fetchAssets();
    if (activeCompany) {
      fetchCompanyData(activeCompany.compId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany]);

  useEffect(() => {
    try {
      fetchOperationMode();
    } catch (error) {
      console.error('failed get operation data', error);
    }
  }, [dataFilter]);

  const tableColumns: any = [
    {
      title: 'Date & Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      width: 180,
      fixed: 'left',
      align: 'center',
    },
    {
      title: 'Starboard',
      fixed: 'left',
      align: 'center',
      children: [
        {
          title: 'RPM',
          dataIndex: 'stbRPM',
          key: 'stbRPM',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'Ops. Mode',
          dataIndex: 'stbOpsMode',
          key: 'stbOpsMode',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons',
          dataIndex: 'stbFuelCons',
          key: 'stbFuelCons',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
    {
      title: 'Center',
      fixed: 'left',
      align: 'center',
      hide: !currentAsset?.isThreeEngine,
      children: [
        {
          title: 'RPM',
          dataIndex: 'ctrRPM',
          key: 'ctrRPM',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'Ops. Mode',
          dataIndex: 'ctrOpsMode',
          key: 'ctrOpsMode',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons',
          dataIndex: 'ctrFuelCons',
          key: 'ctrFuelCons',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
    {
      title: 'PORT',
      fixed: 'left',
      align: 'center',
      children: [
        {
          title: 'RPM',
          dataIndex: 'portRPM',
          key: 'portRPM',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
        {
          title: 'Ops. Mode',
          dataIndex: 'portOpsMode',
          key: 'portOpsMode',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) => (typeof value === 'undefined' ? '-' : value),
        },
        {
          title: 'Fuel Cons',
          dataIndex: 'portFuelCons',
          key: 'portFuelCons',
          width: 100,
          fixed: 'left',
          align: 'center',
          render: (value: any) =>
            typeof value === 'undefined' ? '-' : parseFloat(value).toFixed(2),
        },
      ],
    },
  ]
    .filter((item) => !item.hide)
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          children: item.children.filter((child: any) => !child.hide),
        };
      } else {
        return item;
      }
    });

  const _renderOperationModeBody = (
    opsModeTemplate: any[],
    operationModeSummary: any
  ) => {
    let containers = opsModeTemplate
      .map((item) => item.mrodName)
      .reduce((acc: any, label: any) => {
        acc[label] = { maxRpm: 0, value: 0, runningSeconds: 0 };
        return acc;
      }, {});

    if (!operationModeSummary) {
      return (
        <tbody>
          {Object.keys(containers).map((item: any, idx: number) => (
            <tr key={idx}>
              <td>{item}</td>
              <td>00:00:00</td>
              <td>0</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>00:00:00</td>
            <td>0</td>
          </tr>
        </tbody>
      );
    } else {
      const summary = operationModeSummary.total.mode;
      Object.keys(summary).forEach((key) => {
        const label = getLabelFromKeyOperationMode(key);

        if (label) {
          containers[label].maxRpm += parseInt(summary[key].maxRpm);
          containers[label].value += summary[key].value;
          containers[label].runningSeconds += summary[key].runningSeconds;
        }
      });

      return (
        <tbody>
          {Object.keys(containers).map((item: any, idx: number) => (
            <tr key={idx}>
              <td>{item}</td>
              <td>{toHHMMSS(containers[item].runningSeconds)}</td>
              <td>{containers[item].value.toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>{toHHMMSS(operationModeSummary.total.runningSeconds)}</td>
            <td>{operationModeSummary.total.value.toFixed(2)}</td>
          </tr>
        </tbody>
      );
    }
  };

  return (
    <React.Fragment>
      <ReportActionContent>
        <FilterDashboard
          isLog
          setURLParams={handleSetURLParams}
          searchParams={searchParams}
        />
      </ReportActionContent>

      <Container>
        {currentAsset?.opsModeTemplate && operationModeSummary ? (
          <div style={{ marginTop: '20px', paddingBottom: '30px' }}>
            <table id='summaryTable' className='custom-table'>
              <thead>
                <tr>
                  <th>Operating Mode</th>
                  <th>Running Time</th>
                  <th>Fuel Cons</th>
                </tr>
              </thead>

              {_renderOperationModeBody(
                currentAsset?.opsModeTemplate,
                currentAsset?.operationModeSummary
              )}
            </table>
          </div>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            No Operation Mode Data
          </p>
        )}

        <Table
          className='custom-small-table'
          style={{ marginTop: 5 }}
          size='small'
          bordered
          columns={tableColumns}
          pagination={{
            pageSize: 500,
          }}
          scroll={{ y: windowHeight - 300, x: 700 }}
          dataSource={operationModeSeries}
          components={vt}
        />
      </Container>
    </React.Fragment>
  );
};

export const ReportActionContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: flex-end;
  padding-right: 20px;
`;

const Container = styled.div`
  padding: 20px;
  background-color: #e8ecf3;
`;

export default ReportOperationMode;
